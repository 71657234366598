import React from 'react'
import {
  DashBoardFooter,
  BoxLabelFooter,
  TitleFooter,
  DescriptionFooter,
  BoxTableFooter,
} from '../../DashboardClientesStyled';
import Tables from "../../../../../../components/Tables/Tables";
const DashboardFooter = () => {
  const columns = [
    { id: "muestra", label: "Muestra" },
    { id: "fechaAnalisis", label: "Fecha Análisis" },
    { id: "equipo", label: "Equipo" },
    { id: "ptodetoma", label: "Pto.de Toma" },
    { id: "estadofinal", label: "Estado Final" },
    { id: "buttons" },
  ]
  const rows = [
    {
      muestra: "2822",
      fechaAnalisis: "21/10/2023",
      equipo: "Motorreductor 4 Giracleur Cuba 2",
      ptodetoma: "01-0002-0218-0001",
      estadofinal: "Peligro",
    },
    {
      muestra: "2822",
      fechaAnalisis: "21/10/2023",
      equipo: "Motorreductor 4 Giracleur Cuba 2",
      ptodetoma: "01-0002-0218-0001",
      estadofinal: "Peligro",
    },
    {
      muestra: "2822",
      fechaAnalisis: "21/10/2023",
      equipo: "Motorreductor 4 Giracleur Cuba 2",
      ptodetoma: "01-0002-0218-0001",
      estadofinal: "Peligro",
    },
  ]
  const handleClickButonTable = (value, valueRow) => {
    console.log(value, valueRow);
  }
  return (
    <DashBoardFooter>
      <BoxLabelFooter>
        <TitleFooter>Intervenciones</TitleFooter>
        <DescriptionFooter>Aquí podrá consultar las últimas 3 intervenciones registradas.
          Para crear nuevas intervenciones o realizar consultas, presione  la opción “Intervenciones”  que se encuentra en la cabecera
        </DescriptionFooter>
      </BoxLabelFooter>
      <BoxTableFooter>
        <Tables
          typeTable={"prop"}
          columns={columns}
          rows={rows}
          cRows={6}
          pagination={true}
          onClickButtonTable={(value, valuesRow) => { handleClickButonTable(value, valuesRow) }}
        />
      </BoxTableFooter>
    </DashBoardFooter>
  )
}

export default DashboardFooter