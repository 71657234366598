import React from 'react'
import {
  DashBoardLeft,
  BoxHeaderLeft,
  TitleLeft,
  DescriptionLeft,
} from '../../DashboardClientesStyled';
import BarChartComponent from '../../../../../../components/BarChart/BarChartComponent';
const defaultData = [
  { data: [50, 80, 70], color: '#50A449', label: "Normal" },
  { data: [50, 80, 70], color: '#dbdb4f', label: "Precaución" },
];
const footerLabel = [
  'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
  'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
];
const Left = () => {
  return (
    <DashBoardLeft>
      <BoxHeaderLeft>
        <TitleLeft>Cantidad Total de Muestras por Estado en el Año en Curso</TitleLeft>
        <DescriptionLeft>Aquí podrá consultar los totales mensuales de muestras por estado de toda la Empresa,
          para consultar otros gráficos presione la opción “Reportes” que se encuentra en la cabecera</DescriptionLeft>
      </BoxHeaderLeft>
      
      <BarChartComponent 
      series={defaultData} 
      width={1000}
      height={500}
      grid={{horizontal:true}}
      footerLabel={footerLabel}
      />
      
    </DashBoardLeft>
  )
}

export default Left;

