import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import styled from "styled-components";
const BarChartComponent = (props) => {
  const months = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];
 

  const renderSeries = () => {
    const defaultData = [
      { data: [50, 80, 70], color: '#50A449', label: "Normal" },
      { data: [50, 80, 70], color: '#dbdb4f', label: "Precaución" },
      { data: [50, 80, 70], color: '#E70404', label: "Peligro" },
    ];
    const render = props.series?.map((res) => {
      return { data: res.data, color: res.color, label: res.label };
    }) ?? defaultData;
  
    return render;
  };
  return (
    <Styled>
      <BarChart
         slotProps={{
          legend: {
            direction: 'row',
            position: { vertical: 'top', horizontal: 'middle' },
            labelStyle: {
              fontSize: 25,
              padding:5,
            },
            padding:0
          },
        }}
        xAxis={[{ scaleType: 'band', data: props.footerLabel ?? months }]}
        yAxis={[{ min: 0, max: 90, line: { display: false } }]}
        series={renderSeries()}
        width={props.width ?? 1000}
        height={props.height ?? 500}
        grid={props.grid ?? {horizontal:true}}
      />
    </Styled>
  )
}

export default BarChartComponent

const Styled = styled.div`
  display: flex;
  justify-content: center;
  
  /* .MuiChartsLegend-root{
    position: relative;
    margin-top: 20px;
  } */
  .MuiChartsAxis-directionX{
    .MuiChartsAxis-tick{
      font-weight: bold;
    }
    .MuiChartsAxis-tickLabel {
      transform: rotate(-40deg) translate(-10px, 5%);
      text-anchor: end;
      
      tspan{
        font-size: 20px;
      }
    }
  }
  .MuiChartsAxis-directionY{
    .MuiChartsAxis-line{
      display: none;
    }
    .MuiChartsAxis-tick{
      display: none;
    }
    .MuiChartsAxis-tickLabel {
      tspan{
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
 
  
`;