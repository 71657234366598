import React, { useState } from "react";
import Alert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  RenderImg,
  LimsOil,
  ImgIndex,
  TextField,
  CustomButton,
  Styled,
  DivLogin,
  DivImagen,
  Titulo,
  Formulario,
  DivHr,
  DivBackground,
} from "./LoginStyled.js";
import { useForm } from 'react-hook-form';
import { useAuth } from "../../context/AuthContext.jsx";
import { Navigate } from 'react-router-dom';


const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { login, isAuthenticated } = useAuth();

  const onSubmit = async (value) => {
    try {
      await login(value);
    } catch (err) {
      setError(err && "Usuario o contraseña incorrecta" );
    }
  }
  React.useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  if (isAuthenticated) {
    return <Navigate to='/task' />
  }

  return (
    <Styled>
      <DivLogin>
        <DivImagen>
          <RenderImg img={LimsOil} className="imagenLogin" alt="imLogin" />
        </DivImagen>
        <Titulo>Inicio de sesión</Titulo>
        <Formulario onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type="text"
            {...register("login", { required: true })}
            className="input"
            size="small"
            fullWidth
            error={!!errors.login}
            helperText={errors.login ? 'Usuario requerido' : ''}
            placeholder="Nombre de usuario" />
          <TextField
            type={showPassword ? 'text' : 'password'}
            {...register("password", { required: true })}
            className="input"
            size="small"
            fullWidth
            placeholder="Contraseña"
            error={!!errors.password}
            helperText={errors.password ? 'Contraseña requerida' : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <CustomButton
            label={"Iniciar sesión"}
            type="submit"
            className="buttonColorDefault"
            classNameDiv="divButton"
            fullWidth
            size="large"
            disableRipple
          />
          {error && (
            <Alert severity="error">{error}</Alert>
          )}
        </Formulario>
      </DivLogin>
      <DivHr>
        <hr className="hr" />
      </DivHr>
      <DivBackground>
        <RenderImg img={ImgIndex} className="img" />
      </DivBackground>
    </Styled>
  )
}

export default Login;


