import React, { useReducer, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledModalcomponentClientesExternos } from '../../Styled.jsx';
import { Close } from '@mui/icons-material';
import Input from '../../../../components/Input/Input.jsx';

const InfoMuestrasModal = (props) => {
  console.log(props.datos)
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    nroMuestra: props.datos.nroMuestra ?? '',
    fechaAnalisis: props.datos.fechaAnalisis ?? '',
    planta: props.datos.planta ?? '',
    equipo: props.datos.equipo ?? '',
    ptoToma: props.datos.ptoToma ?? '',
    lubricante: props.datos.lubricante ?? '',
    estadoFinal: props.datos.estadoFinal ?? '',
  });

  const handleClickCancelar = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <StyledModalcomponentClientesExternos sx={{
      width: "60%",
      padding: "40px",
      '@media (max-width: 768px)': {
        width: "90%",
      },
    }}>
      <div className="infoMuestras">
        <div className="divClose" style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "20px" }}>
          <Close onClick={handleClickCancelar} style={{ cursor: "pointer" }} />
        </div>
        <div className="divLabel" >
          <p className="titulo">Informes de Muestras</p>
        </div>
        <form className="formulario">
          <div className="divInputs">
            <div className="divUnoInput">
              <Input
                value={form.nroMuestra}
                label="Número de la Muestra"
                classnamediv="divInput"
                className="input"
                variant="outlined"
                size="medium"
                name="nroMuestra"
              />
              <Input
                value={form.fechaAnalisis}
                label="Fecha Análisis"
                classnamediv="divInput"
                className="input"
                variant="outlined"
                size="medium"
                name="nroMuestra"

              />
              <Input
                value={form.planta}
                label="Planta"
                classnamediv="divInput"
                className="input"
                variant="outlined"
                size="medium"
                name="planta"
              />
            </div>
            <div className="divDosInput">
              <Input
                value={form.equipo}
                label="Equipo"
                classnamediv="divInput"
                className="input"
                variant="outlined"
                size="medium"
                name="equipo"
              />
              <Input
                value={form.ptoToma}
                label="Pto.de Toma"
                classnamediv="divInput"
                className="input"
                variant="outlined"
                size="medium"
                name="ptoToma"
              />
              <Input
                value={form.lubricante}
                label="Lubricante"
                classnamediv="divInput"
                className="input"
                variant="outlined"
                size="medium"
                name="lubricante"
              />

            </div>
            <div className="divImagenes">
              <div className="divLabel" >
                <p className="titulo"> Estado Final </p>
              </div>
              <div className="divEstadoFinal" style={{ textAlign: "center", padding: "10px 0px" }}>
                {form.estadoFinal}
              </div>
            </div>
          </div>
        </form>
      </div>
    </StyledModalcomponentClientesExternos>
  )
}

export default InfoMuestrasModal