import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Styled, StyledTypography } from './CausasDeFallaStyled.js';
import { Search } from '@mui/icons-material';
import { RenderImgStyled } from '../../../Task/Items/SuperAdminStyled';
import { buttonsDefault } from '../../../../components/ButtonsTable/ButtonsTable';
import Input from "../../../../components/Input/Input";
import CustomButton from "../../../../components/Button/CustomButton.tsx";
import More from "../../../../assets/Icons/Buttons/More.svg";
import Tables from "../../../../components/Tables/Tables.jsx";
import Modal from "../../../../components/Modal/Modal.jsx";

const columnsDefaultCfg = [
  { id: "nroMuestra", label: "Nro.Muestra" },
  { id: "fechaAnalisis", label: "Fecha Análisis" },
  { id: "planta", label: "Planta" },
  { id: "equipo", label: "Equipo" },
  { id: "ptoToma", label: "Pto.de Toma" },
  { id: "lubricante", label: "Lubricante" },
  { id: "estadoFinal", label: "Estado Final" },
  { id: "buttons" },
]

const datosRows = [
  {
    nroMuestra: "nroMuestra",
    fechaAnalisis: "fechaAnalisis",
    planta: "planta",
    equipo: "equipo",
    ptoToma: "ptoToma",
    lubricante: "lubricante",
    estadoFinal: "estadoFinal",
  },
  {
    nroMuestra: "nroMuestra",
    fechaAnalisis: "fechaAnalisis",
    planta: "planta",
    equipo: "equipo",
    ptoToma: "ptoToma",
    lubricante: "lubricante",
    estadoFinal: "estadoFinal",
  },
]
const CausasDeFalla = () => {
  const [datosModal, setDatosModal] = useState("");
  const [statusModal, setModalStatus] = useState(false);

  const handleClickButtonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setDatosModal(valueRow);
      setModalStatus(true);
    }
  }

  return (
    <Styled>
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={() => {
          setModalStatus(false);
        }}
        type={"causasDeFallas"}
      />
      <StyledTypography>Causas de Falla</StyledTypography>
      <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px 0px" }}>
        <Box sx={{ width: "30%" }}>
          <Input
            inputProp={true}
            positionIcon={"start"}
            placeholder={"Buscar por código, descripción, etc"}
            icon={<Search />}
            size={"small"}
            onChange={(e) => {
              console.log(e);
            }}
            className="inputComponent"
            style={{ borderRadius: "10px", paddingLeft: "0px" }}
          />
        </Box>
        <CustomButton
          label={"Nueva causa de falla"}
          startIcon={<RenderImgStyled img={More} />}
          variant={"contained"}
          className="buttonHeaderTable"
          onClick={() => { handleClickButtonTable("crear") }}
        />
      </Box>
      <Tables
        columns={columnsDefaultCfg}
        rows={datosRows}
        typeTable={'prop'}
        pagination={true}
        tableColor={false}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => { handleClickButtonTable(value, valuesRow) }}
      />
    </Styled>
  )
}

export default CausasDeFalla