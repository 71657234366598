import React, { useState, useEffect } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Input from "../../Input/Input";
import RenderImg from '../../RenderImg/RenderImg.tsx';
import CustomButton from '../../Button/CustomButton.tsx';
import { useNavigate } from 'react-router-dom';
import { StyledModalNav } from "../Styled.jsx";


const Nav = (props) => {
  const navigate = useNavigate();
  const [resultadosBusqueda, setResultadosBusqueda] = useState(props.datos);
  useEffect(() => {
    setResultadosBusqueda(props.datos);
  }, [props.datos]);

  const handleInputChange = (value) => {
    const normalizedValue = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/,/g, "")
      .trim(); 
    const lowercasedValue = normalizedValue.toLowerCase();
    const keywords = lowercasedValue.split(/\s+/); 

    if (!lowercasedValue) {
      setResultadosBusqueda(props.datos); 
      return;
    }
    const filteredResults = props.datos.filter((item) =>
      keywords.every((keyword) =>
        item.label?.normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(keyword)
      )
    );
    setResultadosBusqueda(filteredResults);
  };
  const renderHeader = () => {
    return <>
      <div className="divIconCerrar" onClick={props.onClick}>
        <CloseIcon className="iconCerrar" />
      </div>

      <div className="divHeaderModal">
        <Input
          inputProp={true}
          positionIcon={"start"}
          placeholder={"buscar opción"}
          icon={<SearchIcon />}
          onChange={(e) => { handleInputChange(e.target.value) }}
          size={"small"}
        />
      </div>

    </>
  }

  const handleNavigate = (ruta) => {
    props.onClick();
    const mainRoute = props.labelModal;
    const rutaLower = ruta.toLowerCase().replace(/\s/g, '').normalize("NFD").replace(/[\u0300-\u036f]/g, '');
    navigate(`/${mainRoute}/${rutaLower}`);
  }
  const renderItems = () => {
    return resultadosBusqueda?.map((res, i) => (
      <div className="divButtonModalIcon" key={i}>
        <div className="divIcon">
          <RenderImg
            img={res.icon}
            width={40.23}
            height={40.23}
          />
        </div>
        <div className="divLabel">
          <CustomButton
            className="label"
            variant={"text"}
            label={res.label}
            onClick={() => { handleNavigate(res.label) }}
            size="medium"
            disableRipple
          />
          <p className="sub">{res.subLabel}</p>
        </div>
      </div>
    ));
  };
  return (
    <StyledModalNav>
      {renderHeader()}
      <div className="buttonss">
        {renderItems()}
      </div>
    </StyledModalNav>
  )
}

export default Nav