import React from 'react'
import {
  DashBoardLeft,
  BoxHeaderLeft,
  TitleLeft,
  DescriptionLeft,
} from '../DashboardLaboratorioStyled';
import BarChartComponent from '../../../../../components/BarChart/BarChartComponent';
const defaultData = [
  { data: [50, 80, 70], color: '#fa0404', label: "Pendiente" },
  { data: [50, 80, 70], color: '#20e616', label: "Publicada" },
];
const footerLabel = [
  'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
  'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
];
const LeftDashboardLaboratorio = () => {
  return (
    <DashBoardLeft>
      <BoxHeaderLeft>
        <TitleLeft>Cantidad Total de Muestras Ingresadas en el Año en Curso</TitleLeft>
        <DescriptionLeft>Aquí podrá consultar los totales mensuales de muestras de acuerdo al estado de diagnóstico
        para consultar otros gráficos presione la opción “Reportes” que se encuentra en la cabecera</DescriptionLeft>
      </BoxHeaderLeft>

      <BarChartComponent
        series={defaultData}
        width={1000}
        height={500}
        grid={{ horizontal: true }}
        footerLabel={footerLabel}
      />

    </DashBoardLeft>
  )
}

export default LeftDashboardLaboratorio;

