import React, { useReducer, useState, useEffect } from 'react';
import Input from '../../../../Input/Input.jsx';
import DropZone from '../../../../DropZone/DropZone.jsx';
import CustomButton from '../../../../Button/CustomButton.tsx';
import MenuItem from '@mui/material/MenuItem';
import Checkboxx from '../../../../Checkbox/Checkboxx.jsx';
import { useNavigate } from 'react-router-dom';
import { StyledModalcomponentMuestras } from '../../../Styled.jsx';

const CargaManualResultadosModal = (props) => {
  // const propsImportacionResultados = (datosUnidades) => {

  //   return {}
  // }
  // const {} = propsImportacionResultados(props.datos);
  const navigate = useNavigate();
  const [formCompleto, setFormCompleto] = useState(false);
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    cliente: '',
    ordenTrabajo: '',
    analisis: '',
    nroMuestra: '',
    valorResultado: '',
  });

  useEffect(() => {
    const campoValido = campo => form[campo] !== '';
    const camposaValidar = ['cliente', 'ordenTrabajo', 'analisis', 'nroMuestra', 'valorResultado'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const fieldValue = value;
    setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  }
  const titulo = () => {
    const titulo = "Carga Manual de Resultados"
    return titulo
  }
  const handleClickCancelar = () => {
    if (props.onClick) {
      props.onClick();
    }
    navigate(-1);
  };

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={handleClickCancelar}
          label={"Cancelar"}
          variant="contained"
          className={`buttonColorDisabled`}
        />

        <CustomButton
          label={"Confirmar"}
          variant="contained"
          className={`buttonColorDefault btn`}
          type="submit"
          disabled={!formCompleto && false}
        />

      </>
    );
  };
  return (
    <StyledModalcomponentMuestras sx={{
      width: "60%",
      padding: "40px",
      '@media (max-width: 768px)': {
        width: "90%",
      },
    }}>
      <div className="cargaResultados">
        <div className="divLabel">
          <p className="titulo">{titulo()}</p>
        </div>
        <form className="formulario" onSubmit={handleSubmit}>
          <div className="divInputs">
            <div className="divUnoInput">
              <Input
                value={form.cliente}
                label="Cliente"
                classnamediv="divInput"
                className="input"
                variant="outlined"
                size="medium"
                name="cliente"
                onChange={handleChange}
                select
              >
                <MenuItem value="option2">Option 2</MenuItem>
                <MenuItem value="option3">Option 3</MenuItem>
              </Input>
              <Input
                value={form.ordenTrabajo}
                label="Orden de Trabajo"
                classnamediv="divInput"
                className="input"
                variant="outlined"
                size="medium"
                name="ordenTrabajo"
                onChange={handleChange}
                select
              >
                <MenuItem value="option2">Option 2</MenuItem>
                <MenuItem value="option3">Option 3</MenuItem>
              </Input>
            </div>
            <div className="divDosInput">
              <Input
                value={form.analisis}
                label="Análisis"
                classnamediv="divInput"
                className="input"
                variant="outlined"
                size="medium"
                name="analisis"
                onChange={handleChange}
                select
              >
                <MenuItem value="option2">Option 2</MenuItem>
                <MenuItem value="option3">Option 3</MenuItem>
              </Input>

            </div>
            <div className="divTresInput">
              <div className="divInputsTres">
                <Input
                  value={form.nroMuestra}
                  label="Número de la Muestra"
                  classnamediv="divInput"
                  className="input"
                  variant="outlined"
                  size="medium"
                  name="nroMuestra"
                  onChange={handleChange}
                />
                <Input
                  value={form.valorResultado}
                  label="Valor Resultado"
                  classnamediv="divInput"
                  className="input"
                  variant="outlined"
                  size="medium"
                  name="valorResultado"
                  onChange={handleChange}
                />
              </div>
              <div className="divLabelInputTres">
                <p className="label">Punto :<span className="span">{props.datos.punto ? props.datos.punto : 'Punto de Ejenmplo'}</span></p>
                <p className="label">Equipo :<span className="span">{props.datos.equipo ? props.datos.equipo : 'Equipo de Ejemplo'}</span></p>
              </div>
            </div>
            <div className="divImagenes">
              imagenes
            </div>
            <div className="divButtonsItem">
              {renderButtons()}
            </div>
          </div>
        </form>
      </div>
    </StyledModalcomponentMuestras>
  )
}

export default CargaManualResultadosModal