import React, { useState } from 'react'
import styled from "styled-components";
import Input from "../../../../components/Input/Input";
import CustomButton from "../../../../components/Button/CustomButton.tsx";
import Tables from '../../../../components/Tables/Tables.jsx';
import More from "../../../../assets/Icons/Buttons/More.svg";
import SetIcon from "../../../../assets/Icons/Buttons/Clientes/SetIcon.svg";
import Modal from '../../../../components/Modal/Modal.jsx';
import { RenderImgStyled } from '../../../Task/Items/SuperAdminStyled';
import { StyledTypography, BoxInputs, BoxComponent } from './StyledIntervencionesClientesExternos';
import { Box } from "@mui/material";
import { Search } from '@mui/icons-material';
import { buttonsDefault } from '../../../../components/ButtonsTable/ButtonsTable.js';
const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]
const columnsDefaultCfg = [
  { id: "fecha", label: "Fecha" },
  { id: "tipoIntervencion", label: "Tipo Intervención" },
  { id: "intervencion", label: "Intervención" },
  { id: "observaciones", label: "Observaciones" },
  { id: "equipo", label: "Equipo" },
  { id: "planta", label: "Planta" },
  { id: "buttons" },
]

const datosRows = [
  {
    fecha: "fecha",
    tipoIntervencion: "tipoIntervencion",
    intervencion: "intervencion",
    observaciones: "observaciones",
    equipo: "equipo",
    planta: "planta",
  },
  {
    fecha: "fecha",
    tipoIntervencion: "tipoIntervencion",
    intervencion: "intervencion",
    observaciones: "observaciones",
    equipo: "equipo",
    planta: "planta",
  }, {
    fecha: "fecha",
    tipoIntervencion: "tipoIntervencion",
    intervencion: "intervencion",
    observaciones: "observaciones",
    equipo: "equipo",
    planta: "planta",
  }
]
const IntervencionesClientesExternos = () => {
  const [keyComponents, setKeyComponents] = useState(0);
  const [datosModal, setDatosModal] = useState("");
  const [statusModal, setModalStatus] = useState(false);

  const handleClickButtonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    }
  }
  const handleClickButtonHeaderTable = (value) => {
    setModalStatus(true);
    setDatosModal({ button: value.toLowerCase()});
  }
  return (
    <Styled>
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={() => {
          setModalStatus(false);
        }}
        type={"intervencionesClientesExternos"}
      />
      <StyledTypography>Intervenciones</StyledTypography>
      <BoxInputs key={keyComponents}>
        <BoxComponent>
          <Box sx={{
            width: "20%", '@media (max-width: 768px)': {
              width: "100%"
            }
          }}>
            <Input
              stylesHeader
              reactSelect
              placeholder="Seleccione la Planta"
              options={options}
            />
          </Box>
          <Box sx={{
            width: "20%", '@media (max-width: 768px)': {
              width: "100%"
            }
          }}>
            <Input
              stylesHeader
              reactSelect
              placeholder="Seleccione el Equipo"
            />
          </Box>
          <Box sx={{
            width: "20%", '@media (max-width: 768px)': {
              width: "100%"
            }
          }}>
            <Input
              stylesHeader
              reactSelect
              placeholder="Seleccione el Tipo de Intervención"
            />
          </Box>
          <Box sx={{
            marginLeft: "auto",
            '@media (max-width: 768px)': {
              width: "100%"
            }
          }}>
            <CustomButton
              label={"Limpiar filtros"}
              startIcon={<RenderImgStyled img={SetIcon} />}
              variant={"link"}
              onClick={() => { setKeyComponents((prevKey) => prevKey + 1); }}
            />
          </Box>
        </BoxComponent>
        <BoxComponent sx={{ display: "flex", gap: "30px", justifyContent: "space-between" }}>
          <Box sx={{
            width: "25%", '@media (max-width: 768px)': {
              width: "100%"
            }
          }}>
            <Input
              inputProp={true}
              positionIcon={"start"}
              placeholder={"Buscar por contenido"}
              icon={<Search />}
              size={"small"}
              onChange={(e) => {
                console.log(e);
              }}
              className="inputComponent"
            />
          </Box>
          <Box>
            <CustomButton
              label={"Nueva intervención"}
              startIcon={<RenderImgStyled img={More} />}
              className={"buttonHeaderTable"}
              size="medium"
              onClick={() => { handleClickButtonHeaderTable("crear") }}
            />
          </Box>

        </BoxComponent>
        <Tables
          columns={columnsDefaultCfg}
          rows={datosRows}
          typeTable={'prop'}
          pagination={true}
          tableColor={false}
          buttons={buttonsDefault}
          onClickButtonTable={(value, valuesRow) => { handleClickButtonTable(value, valuesRow) }}
        />
      </BoxInputs>
    </Styled>
  )
}

export default IntervencionesClientesExternos
const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables{
    height: 100vh;
  }
`