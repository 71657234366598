import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Preloader = ({ loading, title, subtitle }) => (
  loading ? (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <CircularProgress />
      {title && <Typography variant="h6" sx={{ mt: 2 }}>{title}</Typography>}
      {subtitle && <Typography variant="subtitle1" sx={{ mt: 1 }}>{subtitle}</Typography>}
    </Box>
  ) : null
);

export default Preloader;


