import React from 'react';
import Card from './Card.jsx';
import Tables from '../../../../../Tables/Tables.jsx';

import { StyledModalcomponentMuestras } from '../../../../Styled.jsx';
import Checkboxx from '../../../../../Checkbox/Checkboxx.jsx';
import CustomButton from '../../../../../Button/CustomButton.tsx';
import Input from '../../../../../Input/Input.jsx';
import DropZone from '../../../../../DropZone/DropZone.jsx';
import AddIcon from '@mui/icons-material/Add';
import TaskIcon from '@mui/icons-material/Task';
import SendIcon from '@mui/icons-material/Send';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

const columns = [
  { id: "resaltar", label: "Resaltar" },
  { id: "analisis", label: "Análisis" },
  { id: "resultado", label: "Resultado" },
  { id: "unidad", label: "Unidad" }
]
const rows = [{
  resaltar: <Checkboxx />,
  analisis: "Contenido de Agua y Sedimentos",
  resultado: <div className="divRowsMuestra" />,
  unidad: "Miligramo KOH por gramo",

}]
const buttonHeader = [
  {
    label: "Crear nuevo resultado",
    startIcon: <AddIcon />,
    classNameDiv: "divBtnResultado",
    className: "buttonHeaderTable btnResultado"
  },
  {
    label: "Guardar",
    startIcon: <TaskIcon />,
    classNameDiv: "divBtn",
    size: "small",
    className: "buttonColorTable"
  },
  {
    label: "Guardar y PUBLICAR",
    startIcon: <SendIcon />,
    classNameDiv: "divBtn",
    size: "small",
    className: "buttonColorTable"
  },
  {
    label: "Imprimir",
    startIcon: <LocalPrintshopIcon />,
    classNameDiv: "divBtn",
    size: "small",
    className: "buttonColorTable"
  }
]
const inputs = {
  inputsA: [
    {
      label: "Fecha de Análisis",
      className: "input",
      classNameDiv: "divInputAnalisis",
    },
    {
      placeholder: "Lubricante Actual del Punto de Toma",
      inputSelect: true,
      className: "input",
      classNameDiv: "divInputPuntoDeToma"
    },
    {
      label: "Horas Uso Equipo",
      className: "input",
      classNameDiv: "divInputEquipo"
    },
    {
      label: "Horas Uso Lubricante",
      className: "input",
      classNameDiv: "divInputLubricante"
    },
    {
      label: "Última intervención",
      className: "input",
      classNameDiv: "divInputIntervencion"
    },
  ],
  inputsB: [
    {
      placeholder: "Estado  Final",
      inputSelect: true,
      className: "input",
      classNameDiv: "divInputEstado",
    }
  ],
  inputsC: [
    {
      placeholder: "Mensaje Predefiido",
      inputSelect: true,
      className: "input",
      classNameDiv: "divInputMensaje",
    },
    {
      label: "Diagnóstico",
      className: "input",
      classNameDiv: "divInputMensaje",
      multiline: true,
      minRows: 3,
      maxRows: 3
    },
    {
      label: "Sugerencias",
      className: "input",
      classNameDiv: "divInputMensaje",
      multiline: true,
      minRows: 3,
      maxRows: 3
    },
    {
      type:"dropZone"
    },
    {
      type:"dropZone"
    }
  ]

}
const renderInputs = () => {
  const renderA = inputs?.inputsA?.map((res, i) => {
    return <Input
      key={i}
      label={res.label}
      size={res.size}
      placeholder={res.placeholder}
      inputSelect={res.inputSelect}
      className={res.className}
      classnamediv={res.classNameDiv}
    />
  })
  const renderB = inputs?.inputsB?.map((res, i) => {
    return <Input
      key={i}
      label={res.label}
      placeholder={res.placeholder}
      inputSelect={res.inputSelect}
      size={res.size}
      className={res.className}
      classnamediv={res.classNameDiv}
    />
  })
  const renderC = inputs?.inputsC?.map((res, i) => {
    return res.type !== "dropZone" ? (
      <Input
        key={i}
        label={res.label}
        placeholder={res.placeholder}
        inputSelect={res.inputSelect}
        size={res.size}
        type={res.type}
        multiline={res.multiline}
        className={res.className}
        classnamediv={res.classNameDiv}
        minRows={res.minRows}
        maxRows={res.maxRows}
      />
    ) : (
      <DropZone key={i}/>
    );
  })
  return { renderA, renderB, renderC }
}
const renderButtonHeader = () => {
  const render = buttonHeader?.map((res, i) => {
    return <CustomButton
      key={i}
      label={res.label}
      size={res.size}
      startIcon={res.startIcon}
      className={res.className}
      classNameDiv={res.classNameDiv}
    />
  })
  return render;
}
const DiagMuestrasModal = (props) => {
  return (
    <StyledModalcomponentMuestras style={{ width: "75%", height:"90%", overflowY:"auto"}}>
      <div className="diagMuestras">
        <div className="divLabel">
          <p className="titulo"> Diagnóstico de Muestras </p>
        </div>
        <form action="" className="formulario">
          <div className="columnaUno">
            <Card type="cardUno" />
            <div className="divInput">
              {renderInputs().renderA}
            </div>
            <div className="divInputt">
              {renderInputs().renderB}
            </div>
            <div className="divInputtt">
              {renderInputs().renderC}
            </div>
          </div>
          <div className="columnaDos">
            <Card />
            <div className="divHeaderTable">
              {renderButtonHeader()}
            </div>
            <Tables
              columns={columns}
              rows={rows}
              typeTable={'prop'}
              pagination={false}
              tableColor={true}
            />
          </div>

        </form>
      </div>
    </StyledModalcomponentMuestras>
  )
}

export default DiagMuestrasModal