import React, { useEffect } from "react";
import styled from "styled-components";
import {
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import ReactSelect, { components } from "react-select";
import FilterListIcon from "@mui/icons-material/FilterList";
const Input = (props) => {
  const [placeholderSelect, setPlaceholderSelect] = React.useState("");
  const [activeLegendReactSelect, setActiveLegendReactSelect] =
    React.useState("");
  const propsInput = (data) => {
    const className = data?.className ? data.className : "inputComponent";
    const inputProp = data?.inputProp || false;
    const positionIcon = data?.positionIcon
      ? `${data?.positionIcon}Adornment`
      : "startAdornment";
    const icon = data?.icon || null;
    const iconSelect = (
      <div htmlFor="select-icon" className="icon">
        {icon}
      </div>
    );
    const placeholder = data?.placeholder || "";
    const label = data?.label || "";
    const readOnly = data?.InputProps?.readOnly || false;
    const inputSelect = data?.inputSelect || false;
    const datosSelect = data?.datosSelect || [];
    const datosselectdefault = data?.datosselectdefault || [];
    const typeRow = data?.typeRow || "";
    const name = data?.name || "";
    const inputform = data?.inputForm || "false";
    const nameregister = data?.nameRegister || "";
    const reactSelect = data?.reactSelect || false;

    return {
      className,
      inputProp,
      positionIcon,
      icon,
      placeholder,
      readOnly,
      inputSelect,
      datosSelect,
      datosselectdefault,
      typeRow,
      iconSelect,
      label,
      name,
      inputform,
      nameregister,
      reactSelect,
    };
  };

  const {
    className,
    inputProp,
    positionIcon,
    icon,
    placeholder,
    readOnly,
    inputSelect,
    iconSelect,
    label,
    datosselectdefault,
    name,
    reactSelect,
  } = propsInput(props);

  const validTextFieldProps = { ...props };
  delete validTextFieldProps.inputProp;
  delete validTextFieldProps.positionIcon;
  delete validTextFieldProps.typeRow;
  delete validTextFieldProps.datosSelect;
  delete validTextFieldProps.inputSelect;
  const propsReactSelect = { ...props };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      zIndex: "auto",
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      fontFamily: "Inter",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      // border: '2px solid #1565c0',
      height: "55px",
      zIndex: "auto",
      borderRadius: "8px",
      boxShadow: state.isFocused ? "0 0 0 1px rgb(49 130 206 / 40%)" : "none",
      "&:hover": {
        borderColor: "#dbd1d1",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
      zIndex: "9999",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#66BBD3"
        : state.isFocused
        ? "#66BBD3"
        : null,
      color: state.isSelected ? "white" : "inherit",
      cursor: "pointer",
      fontFamily: "roboto",
      ":active": {
        ...provided[":active"],
        backgroundColor: state.isSelected ? "#a28e8e" : "rgb(212, 222, 241)",
        fontFamily: "roboto",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "roboto",
      padding: "0px 5px",
    }),
  };
  const customStylesHeader = {
    container: (provided) => ({
      ...provided,
      zIndex: "auto",
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: "400",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#f8f7f7",
      border: "1px solid transparent",
      height: "38px",
      zIndex: "auto",
      borderRadius: "10px",
      boxShadow: state.isFocused ? "0 0 0 1px rgb(49 130 206 / 40%)" : "none",
      "&:hover": {
        border: "1px solid transparent",
      },
      "&:active": {
        border: "1px solid transparent",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
      zIndex: "9999",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#66BBD3"
        : state.isFocused
        ? "#66BBD3"
        : null,
      color: state.isSelected ? "white" : "inherit",
      cursor: "pointer",
      fontFamily: "roboto",
      ":active": {
        ...provided[":active"],
        backgroundColor: state.isSelected ? "#a28e8e" : "rgb(212, 222, 241)",
        fontFamily: "roboto",
      },
    }),
    singleValue: (provided, base) => ({
      ...provided,
      ...base,
      fontFamily: "roboto",
      padding: "0px 5px",
      display: "flex",
      alignItems: "center",
    }),
  };

  // console.log(props.value);

  useEffect(() => {
    if (props.value) {
      handleChangeReactSelect(props.value);
    }
  }, [props.value, props.onChange, setActiveLegendReactSelect]);

  const handleChangeReactSelect = (val) => {
    setActiveLegendReactSelect(val?.label ? val?.label : "");
  };
  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <FilterListIcon /> {children}
    </components.Control>
  );

  return (
    <Styled className={props.classnamediv ? props.classnamediv : "divInput"}>
      {reactSelect ? (
        <div
          style={{
            ...props.styledReactSelect,
            position: "relative",
            padding: "5px 0px",
          }}
        >
          {placeholder && activeLegendReactSelect?.length > 0 && (
            <div
              style={{
                background:
                  "linear-gradient(to bottom, white 60%, #F7F7F8 30%)",
                position: "absolute",
                top: "-7px",
                left: "10px",
                padding: "0px 5px",
                zIndex: 99,
                fontFamily: "inter",
                color: "#163C5B",
              }}
            >
              {placeholder}
            </div>
          )}
          <ReactSelect
            {...propsReactSelect}
            isClearable={props.isClearable ? props.isClearable : false}
            options={props.options}
            placeholder={placeholder}
            value={props.value}
            onChange={(e) => {
              handleChangeReactSelect(props.value ? props.value : e);
              if (props.onChange) {
                props.onChange(e);
              }
            }}
            styles={props.stylesHeader ? customStylesHeader : customStyles}
            components={
              props.stylesHeader
                ? {
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    Control,
                  }
                : { IndicatorSeparator: () => null }
            }
          />
        </div>
      ) : inputSelect ? (
        <FormControl className={className}>
          <InputLabel htmlFor="select-icon">{placeholder}</InputLabel>
          {iconSelect}
          <Select
            {...validTextFieldProps}
            value={placeholderSelect}
            label={placeholder}
            name={name}
            onChange={(e) => {
              setPlaceholderSelect(e.target.value);
              props.onChange && props.onChange(e);
            }}
            inputProps={{
              //name: 'select-icon',
              id: "select-icon",
            }}
            renderValue={placeholderSelect !== "" ? undefined : null}
          >
            {datosselectdefault &&
              datosselectdefault.map((option, key) => (
                <MenuItem key={key} value={option}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      ) : (
        <TextField
          {...validTextFieldProps}
          className={className}
          placeholder={placeholder}
          label={label}
          name={name}
          InputProps={{
            [positionIcon]: inputProp && (
              <InputAdornment position="start">{icon}</InputAdornment>
            ),
            readOnly: readOnly,
          }}
        />
      )}
    </Styled>
  );
};

export default Input;

const Styled = styled.div`
  .inputDefault {
  }
  .inputComponent {
    border-radius: 20px;
    background-color: #f6f8f8;
    width: 100%;
    fieldset {
      border: none;
    }
  }

  .inputSelect {
    border-radius: 20px;
    background-color: #f6f8f8;
    .icon {
      position: absolute;
      top: 10px;
      font-size: 20px;
      left: 10px;
      z-index: 1;
    }
    label {
      top: -5px;
      left: 20px;
    }
    .MuiSelect-select {
      padding: 10px 35px;
      font-size: 15px;
      font-family: Inter;
    }
    width: 100%;
    fieldset {
      border: none;
    }
  }
`;
