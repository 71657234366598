import React, { useState, createContext, useContext } from "react";
import { loginUser } from "../api/auth";
import { tokenAxios } from "../api/axios";
import { jwtDecode } from "jwt-decode";
export const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("x")
  }
  return context;
}

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const storedAuth = localStorage.getItem('isAuthenticated');
    return storedAuth ? JSON.parse(storedAuth) : false;
  });
  const [token, setToken] = useState(() => {
    const storedToken = localStorage.getItem('token');
    const tokenUnlocked = storedToken ? jwtDecode(storedToken) : null;
    return tokenUnlocked;
  });

  const login = async (value) => {
    try {
      const response = await loginUser(value);
      const token = response.data;
      tokenAxios(token);
      const decodedToken = jwtDecode(token);
      setToken(decodedToken);
      setIsAuthenticated(true);
      localStorage.setItem('token', token);
      localStorage.setItem('isAuthenticated', 'true');
    } catch (err) {
      throw err.response.data;
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem('isAuthenticated');
    setIsAuthenticated(false);
    setToken(null);
  };
  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, token }}>
      {children}
    </AuthContext.Provider>
  )
}
