import React, { useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CustomButton from "../Button/CustomButton.tsx";
import styled from "styled-components";

const DropZone = ({ onFileSelected, onClickDropZone }) => {
  const [imageUrl, setImageUrl] = useState("");
  const inputRef = useRef(null);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    noClick: true,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);
      onFileSelected(file);
    },
  });

  const removeImage = () => {
    setImageUrl("");
    onFileSelected(null);
  };

  const openDialog = () => {
    inputRef.current.click();
    if (onClickDropZone) {
      alert("hola");
      onClickDropZone();
    }
  };

  return (
    <Styled>
      <div {...getRootProps({ className: "dropzone" })}>
        {imageUrl ? (
          <div className="divFile">
            <img src={imageUrl} alt="Uploaded" />
            <DeleteOutlinedIcon className="deleteIcon" onClick={removeImage} />
          </div>
        ) : (
          <>
            <input {...getInputProps()} ref={inputRef} />
            <div className="divIcon">
              <UploadFileIcon fontSize="medium" />
            </div>
            <div className="divButton">
              <CustomButton
                label="Click para subir archivo"
                onClick={openDialog}
                className="button"
                size="small"
              />
              <p className="label">o arrastre aquí el archivo</p>
            </div>

            <div className="divSub">
              <p className="sub">PNG, JPG (max. 3MB)</p>
            </div>
          </>
        )}
      </div>
    </Styled>
  );
};
export default DropZone;

const Styled = styled.div`
  /* padding:20px 0px; */
  /* <input {...getInputProps()} /> */
  .title {
    position: absolute;
    z-index: 1;
    background: white;
    top: 48px;
    left: 30px;
    color: #545454;
    font-family: Inter;
    font-weight: bold;
    font-size: 12px;
  }
  .dropzone {
    border: 2px dashed #afccd5;
    padding: 20px;
    text-align: center;
    position: relative;
    z-index: 0;
  }
  .divIcon {
    svg {
      color: #2196f3;
    }
  }
  .divButton {
    display: flex;
    align-items: center;
    justify-content: center;
    .button {
      text-decoration: underline;
      color: #2196f3;
    }
    .label {
      font-family: Inter;
      font-weight: bold;
      margin: 0px;
      font-size: 12px;
    }
  }
  .divSub {
    .sub {
      color: #b0b0b0;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
    }
  }
  .divFile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 200px;
    height: 200px;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .text-overlay {
    position: absolute; /* Hace que la posición del texto sea absoluta en relación con el contenedor */
    top: 50%; /* Coloca el texto en el centro vertical del contenedor */
    left: 50%; /* Coloca el texto en el centro horizontal del contenedor */
    transform: translate(-50%, -50%); /* Centra el texto completamente */
    color: #000; /* Establece el color del texto */
  }
`;
