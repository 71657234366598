import React, { useState, useEffect } from 'react';
import Tables from '../../../../components/Tables/Tables';
import HeaderTable from '../../../../components/HeaderTable/HeaderTable';
import Modal from "../../../../components/Modal/Modal";
import { buttonsDefault } from '../../../../components/ButtonsTable/ButtonsTable';
import styled from 'styled-components';
import Toast from '../../../../components/Toast/Toast';
import { getEstasdosFinales, deleteEstadosFinales , getEstadosFinalesPaginated,getTipoUnidades, createUnidad, deleteTipoUnidad } from '../../../../api/Configuracion';
import Modal2 from "../../../../components/Modal/Modal";

const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "abreviatura", label: "Abreviatura" },
  { id: "orden", label: "Orden" },
  { id: "color", label: "Color" },
  { id: "imagen", label: "Imagen" },
  { id: "buttons" },
]
const rows = [
  {
    codigo: "Código",
    descripcion: "Descripción",
    abreviatura: "Abreviatura",
    orden: "Orden",
    color: "Color",
    imagen: "Imagen",
    id:"5",
  }
]

const EstadosFinales = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [estadosFinales, setEstadosFinales] = useState([]);
  const [estadosFinalesId, setEstadosFinalesId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
    const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  
  useEffect(() => {
    getEstadosFinalesPaginated(paginaActual, orderBy, searchTerm).then((res) => {
     // alert(JSON.stringify(res.data))
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      setEstadosFinales(res.data.items.map((res) => {
        return ({ id: res.id,codigo: res.codigo_efm, descripcion: res.desc_efm, abreviatura: res.abrev_efm,orden:res.orden_efm, color: res.color_efm, imagen:res.imagen_efm });
    }));
    })
      },[searchTerm, paginaActual, orderBy]);

      
  useEffect(() => {
    getEstadosFinalesPaginated(paginaActual, orderBy, searchTerm).then((res) => {
     // alert(JSON.stringify(res.data))
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      setEstadosFinales(res.data.items.map((res) => {
        return ({ id: res.id,codigo: res.codigo_efm, descripcion: res.desc_efm, abreviatura: res.abrev_efm,orden:res.orden_efm, color: res.color_efm, imagen:res.imagen_efm });
    }));
    })
      },[])
  const handleClickButonTable = (value, valueRow) => {
    console.log(valueRow);
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({button:value.toLowerCase(),datos:valueRow});
    }else{
      setEstadosFinalesId(valueRow.id);
      setModalStatus2(true);
     // handleDelete(valueRow.id)
    }
  }
  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  }

  const handleDelete = (id) => {
    deleteEstadosFinales(id)
      .then(() => {
        setEstadosFinalesId("");
        return getEstadosFinalesPaginated(paginaActual, orderBy, searchTerm);

      })
      .then(res => {
        setSuccess(true);
        setMsjToast("Estado final eliminado con exito");
        setTimeout(() =>{ setSuccess(false);
          setEstadosFinales(res.data.items.map((res) => {
            setPageCount(res.data.totalPages);
            setItemsCount(res.data.totalItems);
            return ({ id: res.id,codigo: res.codigo_efm, descripcion: res.desc_efm, abreviatura: res.abrev_efm,orden:res.orden_efm, color: res.color_efm, imagen:res.imagen_efm });
          }));
        }, 1500);
        
      })
      .catch(err => console.error("Error al eliminar el estado final:", err));
  };
  
  const handleOrder = (value) => {
    let valor;
    if(orderBy==="" || orderBy.replace("-","").length!==value.length ){
      valor=value;
    }else{
     
      valor=orderBy
    }
  
    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;       
      setOrderBy(updatedValue);
    }
  };
  return (
    <Styled>
      <div className="toast" > 
      {success? <Toast title={msjToast} close={() => setSuccess(false)}/> : null}
      {error? <Toast type={"error"} title={msjToast} close={() => setError(false)}/> : null}
  </div> 
    <Modal
      datos={datosModal}
      isOpen={statusModal}
      onClose={() => {setModalStatus(false);
      getEstadosFinalesPaginated(paginaActual, orderBy, searchTerm).then((res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setEstadosFinales(res.data.items.map((res) => {
          return ({ id: res.id,codigo: res.codigo_efm, descripcion: res.desc_efm, abreviatura: res.abrev_efm,orden:res.orden_efm, color: res.color_efm, imagen:res.imagen_efm });
        }));
      })
      }}
      type={"estadosFinales"}
    // iconX={valueTicket === "Ver"}
    />
    <HeaderTable
      styledHead="cfg"
      cfg
      title="Estados Finales"
      valueBuscador={(value) =>setSearchTerm(value)}
      labelButton={"Crear nuevo estado final"}
      onClickButton={() => { handleClickButtonHeader() }}
    />

    <Tables
      typeTable={"prop"}
      columns={columns}
      rows={estadosFinales}
      cRows={20}
      totalRows={itemsCount}
      pagination={true}
      onClickSwitch={(value) => {handleOrder(value) }}
      page={(e)=>setPaginaActual(e+1)}
      pages={pageCount}
      // valueBuscador={valueBuscador}
      buttons={buttonsDefault}
      onClickButtonTable={(value, valuesRow) => { handleClickButonTable(value, valuesRow) }}
    />
    <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDelete(estadosFinalesId);
        }}
      ></Modal2>

  </Styled>
  )
}

export default EstadosFinales

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables{
    //height: 100vh;
  }
`