import React from 'react';
import { StyledDefault, DivHeader, Title, DivContent, DivIconClose } from "../ModalStyled";
import CloseIcon from '@mui/icons-material/Close';

const Default = (props) => {
  return (
    <StyledDefault className="modalDefault" style={{width: "50%"}}>
      {props.iconX ? <DivIconClose onClick={props.onClose}><CloseIcon/></DivIconClose> : null}
      <DivContent>
        <DivHeader>
          <Title>Cliente TENANT</Title>
        </DivHeader>

        {props.html}
      </DivContent>
    </StyledDefault>
  )
}

export default Default

