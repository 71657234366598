import React, { useState } from 'react';
import Modal from "../../../../components/Modal/Modal";

const CargaManualResultados = () => {
  const [statusModal, setModalStatus] = useState(true);
  const [datosModal, setDatosModal] = useState("");
  return (
    <> 
    <Modal
      datos={datosModal}
      isOpen={statusModal}
      onClose={() => setModalStatus(false)}
      type={"cargaManualResultados"}
    />
    </>
  )
}

export default CargaManualResultados