import React, { useReducer, useState, useEffect } from 'react';
import Input from '../../../../Input/Input.jsx';
import CustomButton from '../../../../Button/CustomButton.tsx';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { StyledModalcomponentMuestras } from '../../../Styled.jsx';
import MenuItem from '@mui/material/MenuItem';
const data = [
  { label: "hola", value: "1" }
]

const MuestrasModal = (props) => {
  const propsMuestras = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const diagnostico = datosUnidades?.button === "diagnóstico" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, diagnostico, datos }
  }

  const { ver, diagnostico, datos } = propsMuestras(props.datos);
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    codigo: datos.codigo ?? 'hola',
    lote: datos.lote ?? '',
    denominacion: datos.denominacion ? dayjs(datos.fecha) : null,
    cliente: datos.cliente ?? '',
    planta: datos.planta ?? '',
    estado: datos.estado ?? '',
    fecha: datos.fecha ? dayjs(datos.fecha) : null,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    const fieldValue = value;
    setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
  };
  const handleChangeCalendar = (e, nameCalendar) => {
    const name = nameCalendar;
    const value = e.$d;
    setForm({ type: 'SET_FIELD', field: name, value: value });
  };
  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label="Cancelar"
          variant="contained"
          className={"buttonColorDisabled"}
        />

        <CustomButton
          label={"Confirmar"}
          variant="contained"
          className={`buttonColorDefault btn`}
          type="submit"
          disabled={false}
        />

      </>
    );
  };
  const titulo = () => {
    const titulo = ver ? "Ver Muestras" : "Crear o editar Muestras";
    return titulo
  }

  return (
    <StyledModalcomponentMuestras sx={{
      width: "70%",
      '@media (max-width: 768px)': {
        width: "95%",
        overflowY: "scroll",
        height: "70%",
      },
    }}>
     
      <div className="muestras">
        <div className="divLabel">
          <p className="titulo"> {titulo()} </p>
        </div>
        <form className="formulario">
          <div className="columna">
            <div className="a">
              <Input
                inputSelect
                classnamediv="divInput"
                className="input"
                datosselectdefault={data}
                placeholder="Cliente"
                onChange={handleChange} 
                value={form.codigo}
                />
              <Input
                inputSelect
                classnamediv="divInput"
                className="input"
                datosselectdefault={data}
                placeholder="Orden de Trabajo" 
                onChange={handleChange}/>
            </div>
            <div className="b">
              <Input
                inputSelect
                classnamediv="divInput"
                className="input"
                datosselectdefault={data}
                placeholder="Punto de Toma" 
                onChange={handleChange}/>
              <Input
                inputSelect
                classnamediv="divInput"
                className="input"
                datosselectdefault={data}
                placeholder="Conjunto de Análisis" 
                onChange={handleChange}/>
            </div>
            <div className="c">
             
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                    <DatePicker
                      className="inputDate"
                      label="Fecha Toma de Muestra"
                      placeholder="Fecha Toma de Muestra"
                      // value={form.fecha}
                      slotProps={<Input
                        className="input inputDate"
                        variant="outlined"
                        size={"medium"}
                      />}
                      onChange={(e) => { handleChangeCalendar(e, "fecha") }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              <Input
                classnamediv="divInput"
                className="input"
                datosselectdefault={data}
                placeholder="Responsable Toma de Muestra" 
                onChange={handleChange}/>
            </div>
            <div className="d">
              <Input
                classnamediv="divInputL divInput"
                className="input"
                datosselectdefault={data}
                placeholder="Horas Uso Equipo"
                onChange={handleChange} />
              <Input
                classnamediv="divInputR divInput"
                className="input"
                datosselectdefault={data}
                placeholder="Lubricante del Punto de Toma (sugerido)" 
                onChange={handleChange}/>
            </div>
            <div className="e">
              <Input
                classnamediv="divInputL divInput"
                className="input"
                datosselectdefault={data}
                placeholder="Horas Uso Lubricante" 
                onChange={handleChange}/>
              <Input
                inputSelect
                classnamediv="divInputR divInput"
                className="input"
                datosselectdefault={data}
                placeholder="Lubricante Actual del Punto de Toma" 
                onChange={handleChange}/>
            </div>
            <div className="f">
              <Input
                classnamediv="divInputL divInput"
                className="input"
                datosselectdefault={data}
                placeholder="Código de la Muestra" 
                onChange={handleChange}/>
              <Input
                inputSelect
                classnamediv="divInputR divInput"
                className="input"
                datosselectdefault={data}
                placeholder="Descripción"
                onChange={handleChange} />
            </div>
          </div>
          <div className="divButtonsItem">
            {renderButtons()}
          </div>
        </form>
      </div>
    </StyledModalcomponentMuestras>
  )
}

export default MuestrasModal;