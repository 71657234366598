import React, { useState } from 'react';
import styled from 'styled-components';
import Input from "../../../../components/Input/Input";
import CustomButton from "../../../../components/Button/CustomButton.tsx";
import Checkboxx from '../../../../components/Checkbox/Checkboxx.jsx';
import Tables from '../../../../components/Tables/Tables.jsx';
import Modal from '../../../../components/Modal/Modal.jsx';
import SetIcon from "../../../../assets/Icons/Buttons/Clientes/SetIcon.svg";
import Print from "../../../../assets/Icons/Buttons/Muestras/Print.svg";
import { Search } from '@mui/icons-material';
import { StyledTypography, BoxInputs, BoxComponent } from './StyledInfoMuestras';
import { Box } from "@mui/material";
import { RenderImgStyled } from '../../../../components/Tables/TablesStyled';
import { buttonsInfoMuestras } from '../../../../components/ButtonsTable/ButtonsTable.js';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]
const dataCheckBox = [
  {
    label: "En Peligro",
    value: 0
  },
  {
    label: "En Precaución",
    value: 1
  },
  {
    label: "En Advertencia",
    value: 2
  },
  {
    label: "Normal",
    value: 3
  },

]
const columnsDefaultCfg = [
  { id: "nroMuestra", label: "Nro.Muestra" },
  { id: "fechaAnalisis", label: "Fecha Análisis" },
  { id: "planta", label: "Planta" },
  { id: "equipo", label: "Equipo" },
  { id: "ptoToma", label: "Pto.de Toma" },
  { id: "lubricante", label: "Lubricante" },
  { id: "estadoFinal", label: "Estado Final" },
  { id: "buttons" },
]

const datosRows = [
  {
    nroMuestra: "nroMuestra",
    fechaAnalisis: "fechaAnalisis",
    planta: "planta",
    equipo: "equipo",
    ptoToma: "ptoToma",
    lubricante: "lubricante",
    estadoFinal: "estadoFinal",
  },
  {
    nroMuestra: "nroMuestra",
    fechaAnalisis: "fechaAnalisis",
    planta: "planta",
    equipo: "equipo",
    ptoToma: "ptoToma",
    lubricante: "lubricante",
    estadoFinal: "estadoFinal",
  },
]

const InfoMuestras = () => {
  const [keyComponents, setKeyComponents] = useState(0);
  const [datosModal, setDatosModal] = useState("");
  const [statusModal, setModalStatus] = useState(false);

  const handleClickButtonTable = (value, valueRow) => {
    if (value !== "Imprimir") {
      setDatosModal(valueRow);
      setModalStatus(true);
    }
  }


  return (
    <Styled>
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={() => {
          setModalStatus(false);
        }}
        type={"infoMuestras"}
      />
      <StyledTypography>Informes de Muestras</StyledTypography>
      <BoxInputs key={keyComponents}>
        <BoxComponent>
          <Box sx={{
            width: "20%", '@media (max-width: 768px)': {
              width: "100%"
            }
          }}>
            <Input
              stylesHeader
              reactSelect
              placeholder="Seleccione la Planta"
              options={options}
            />
          </Box>
          <Box sx={{
            width: "20%", '@media (max-width: 768px)': {
              width: "100%"
            }
          }}>
            <Input
              stylesHeader
              reactSelect
              placeholder="Seleccione el Equipo"
            />
          </Box>
          <Box sx={{
            width: "20%", '@media (max-width: 768px)': {
              width: "100%"
            }
          }}>
            <Input
              stylesHeader
              reactSelect
              placeholder="Seleccione el Punto de Toma"
            />
          </Box>
          <Box sx={{
            width: "20%", '@media (max-width: 768px)': {
              width: "100%"
            }
          }}>
            <Input
              inputProp={true}
              positionIcon={"start"}
              placeholder={"Buscar por nro. muestra"}
              icon={<Search />}
              size={"small"}
              onChange={(e) => {
                console.log(e);
              }}
              className="inputComponent"
              style={{ borderRadius: "10px", paddingLeft: "0px" }}
            />
          </Box>
          <Box sx={{
            width: "20%", '@media (max-width: 768px)': {
              width: "100%"
            }
          }}>
            <Input
              inputProp={true}
              positionIcon={"start"}
              placeholder={"Buscar por nro. de OT"}
              icon={<Search />}
              size={"small"}
              onChange={(e) => {
                console.log(e);
              }}
              className="inputComponent"
              style={{ borderRadius: "10px", paddingLeft: "0px" }}
            />
          </Box>
        </BoxComponent>
        <BoxComponent sx={{ display: "flex", gap: "30px", alignItems: "center" }}>
          <Box sx={{
            width: "25%", '@media (max-width: 768px)': {
              width: "100%"
            }
          }}>
            <Input
              inputProp={true}
              positionIcon={"start"}
              placeholder={"Buscar por contenido"}
              icon={<Search />}
              size={"small"}
              onChange={(e) => {
                console.log(e);
              }}
              className="inputComponent"
            />
          </Box>
          <Box sx={{
            width: "20%", '@media (max-width: 768px)': {
              width: "100%"
            }
          }}>
            <Input
              stylesHeader
              reactSelect
              placeholder="Seleccione el Punto de Toma"
            />
          </Box>
          <Box sx={{
            width: "20%", '@media (max-width: 768px)': {
              width: "100%"
            }
          }}>
            <Input
              stylesHeader
              reactSelect
              placeholder="Seleccione el Punto de Toma"
            />
          </Box>
          <Box sx={{
            width: "20%", '@media (max-width: 768px)': {
              width: "100%"
            }
          }}>
            <Input
              stylesHeader
              reactSelect
              placeholder="Seleccione el Punto de Toma"
            />
          </Box>
          <Box sx={{
            width: "15%", '@media (max-width: 768px)': {
              width: "100%"
            }
          }}>
            <CustomButton
              label={"Limpiar filtros"}
              startIcon={<RenderImgStyled img={SetIcon} />}
              variant={"link"}
              onClick={() => { setKeyComponents((prevKey) => prevKey + 1); }}
            />
          </Box>
        </BoxComponent>
        <BoxComponent sx={{ display: "flex", justifyContent: "space-between", padding: "10px 0px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            {
              dataCheckBox?.map((res, i) => {
                return <Checkboxx
                  label={res.label}
                  value={res.value}
                  key={i}
                  onChange={(e) => { console.log(e) }} />
              })
            }
          </Box>
          <Box>
            <CustomButton
              label={"Resumen de Informes"}
              startIcon={<RenderImgStyled img={Print} />}
              className={"buttonColorTable"}
              size="medium"
              onClick={() => { alert("resumen Informes") }}
            />
          </Box>
        </BoxComponent>
      </BoxInputs>
      <Tables
        columns={columnsDefaultCfg}
        rows={datosRows}
        typeTable={'prop'}
        pagination={true}
        tableColor={false}
        buttons={buttonsInfoMuestras}
        onClickButtonTable={(value, valuesRow) => { handleClickButtonTable(value, valuesRow) }}
      />
    </Styled>
  )
}

export default InfoMuestras
const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables{
    height: 100vh;
  }
`