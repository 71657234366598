import React from 'react';
import CustomButton from '../../Button/CustomButton.tsx';
import CloseIcon from '@mui/icons-material/Close';
import { StyledModalcomponent } from '../Styled';

const Eliminar = (props) => {

  return (
    <StyledModalcomponent>
      <div className="divClose">
        <CloseIcon onClick={() => { props.onClick() }} style={{ cursor: "pointer" }} />
      </div>
      <div className="eliminar">
        <div className="divLabel" >
          <p className="titulo"> Eliminar </p>
        </div>
        <div className="divBodyEliminar">
          <p className="labelBody">Una vez eliminado el registro, no podrá recuperarlo.</p>
          <p className="labelBody">¿Confirma la eliminación?</p>
      
        </div>
        <div className="divButtonsEliminar">
          <CustomButton
            label={"No"}
            variant="contained"
            className={`buttonColorDisabled btn`}
            type="submit"
            onClick={() => { props.onClick() }}
          />
          <CustomButton
            label={"Si"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            onClick={() => { props.onClickSuccess() }}
          />
        </div>
      </div>
    </StyledModalcomponent>
  )
}

export default Eliminar