
import React from 'react';

interface Props {
  img?: string;
  alt?: string;
  className?: string;
  width?: string;
  height?: string;
  material?: React.ReactNode;
  onClick?: () => void;
}

const RenderImg: React.FC<Props> = ({ img, alt, material, className, width, height, onClick }: Props) => {
  return <>
    {material ? material : <img src={img} alt={alt} className={className} width={width} height={height} onClick={onClick} />}
  </>
}

export default RenderImg