import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import styled from "styled-components";
import ClientesIcon from "../../../../assets/Icons/Buttons/Clientes/ClientesIcon.svg";
import RenderImg from "../../../../components/RenderImg/RenderImg.tsx";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Toast from "../../../../components/Toast/Toast";
import Modal2 from "../../../../components/Modal/Modal";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import {
  deleteCliente,
  getClientesPaginated,
  getClientesSearch,
} from "../../../../api/Clientes.js";
import { useNavigate } from "react-router-dom";

const columns = [
  { id: "codigo", label: "Código" },
  { id: "denominacion", label: "Denominación" },
  { id: "email", label: "EMail" },
  { id: "telefono", label: "Teléfono" },
  { id: "logo", label: "Logo" },
  { id: "buttons" },
];
const rows = [
  {
    codigo: "Código",
    denominacion: "Denominación",
    email: "pepe@pepe.com",
    telefono: "75564645",
    logo: "no hay logo",
  },
];
const button = {
  titleToolTip: "Plantas",
  icon: <RenderImg img={ClientesIcon} style={{ padding: "5px" }} />,
  className: "buttonClientes",
};
const buttonsHeadTable = [
  {
    label: "Plantas",
    icon: <ArrowForwardIcon />,
    classname: "buttonColorTable",
    navigate: "/clientes/plantas",
    size: "medium",
  },
  {
    label: "Nuevo cliente",
    classname: "buttonHeaderTable",
  },
];

const Clientes = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [clientes, setClientes] = useState([]);
  const [clienteId, setClienteId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [statusModal2, setModalStatus2] = useState(false);
  const [key, setKey] = useState(1);
  const combinedButtons = [button, ...buttonsDefault];

  const Navigate = useNavigate();

  useEffect(() => {

    getClientesPaginated(paginaActual, orderBy, searchTerm).then((res) => {

      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
      // alert(JSON.stringify(res.data))
      setClientes(
        res.data.items.map((res) => {
          return {
            id: res.id,
            codigo: res.codigo_cli,
            denominacion: res.desc_cli,
            email: res.emails_cli,
            telefono: res.telefonos_cli,
            logo: res.logo_cli,
            direccion: res.direccion_cli,
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    getClientesPaginated(paginaActual, orderBy, searchTerm)
      .then((res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setClientes(
          res.data.items.map((res) => {
            return {
              id: res.id,
              codigo: res.codigo_cli,
              denominacion: res.desc_cli,
              email: res.emails_cli,
              telefono: res.telefonos_cli,
              logo: res.logo_cli,
              direccion: res.direccion_cli,
            };
          })
        );
      })
      .catch((err) => {
        console.error("Error al obtener los Clientes:", err);
      });
  }, [paginaActual, orderBy]);

  useEffect(() => {
    if (searchTerm === "") {
      getClientesPaginated(paginaActual, orderBy, searchTerm)
        .then((res) => {
          setPageCount(res.data.totalPages);
          setItemsCount(res.data.totalItems);
          setClientes(
            res.data.items.map((res) => {
              return {
                id: res.id,
                codigo: res.codigo_cli,
                denominacion: res.desc_cli,
                email: res.emails_cli,
                telefono: res.telefonos_cli,
                logo: res.logo_cli,
                direccion: res.direccion_cli,
              };
            })
          );
        })
        .catch((err) => {
          console.error("Error al obtener los Clientes:", err);
        });
    } else {
      getClientesSearch(searchTerm)
        .then((res) => {
          setPageCount(res.data.totalPages);
          setItemsCount(res.data.totalItems);
          setClientes(
            res.data.items.map((res) => {
              return {
                id: res.id,
                codigo: res.codigo_cli,
                denominacion: res.desc_cli,
                email: res.emails_cli,
                telefono: res.telefonos_cli,
                logo: res.logo_cli,
                direccion: res.direccion_cli,
              };
            })
          );
        })
        .catch((err) => {
          console.error("Error al obtener los Clientes:", err);
        });
    }
  }, [searchTerm]);

  const handleDeleteCliente = (clienteId) => {
    deleteCliente(clienteId)
      .then(() => {
        setClienteId("");
        let paginaActualTemp = paginaActual;
        if (clientes.length - 1 === 0 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        return getClientesPaginated(
          paginaActualTemp,
          "denominacion",
          searchTerm
        );
      })
      .then((res) => {
        setKey(key + 1);
        setMsjToast("Cliente eliminado con exito.");
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          setPageCount(res.data.totalPages);
          setItemsCount(res.data.totalItems);
          setClientes(
            res.data.items.map((res) => {
              return {
                id: res.id,
                codigo: res.codigo_cli,
                denominacion: res.desc_cli,
                email: res.emails_cli,
                telefono: res.telefonos_cli,
                logo: res.logo_cli,
                direccion: res.direccion_cli,
              };
            })
          );
        }, 1500);
      })
      .catch((err) => {
        setKey(key + 1);
        setMsjToast("Error al eliminar el cliente.");
        setError(true);
      });
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  const handleClickButonTable = (value, valueRow) => {
    if (value === "Plantas") {
      Navigate(`/clientes/plantas`, {
        state: { ruta: value, row: valueRow },
      });
    }
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setClienteId(valueRow.id);
      setModalStatus2(true);
    }
  };

  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };

  return (
    <Styled key={key}>
      <div className="toast">
        {success ? (
          <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            key={key}
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        ) : null}
      </div>

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            setKey(key + 1);
            setSuccess(true);
            setMsjToast(msj);
            getClientesPaginated(paginaActual, orderBy, searchTerm).then(
              (res) => {
                if (clientes.length + 1 === 21) {
                  setPaginaActual(res.data.totalPages);
                }
                setPageCount(res.data.totalPages);
                setItemsCount(res.data.totalItems);
                setClientes(
                  res.data.items.map((res) => {
                    return {
                      id: res.id,
                      codigo: res.codigo_cli,
                      denominacion: res.desc_cli,
                      direccion: res.direccion_cli,
                      email: res.emails_cli,
                      telefono: res.telefonos_cli,
                      logo: res.logo_cli,
                    };
                  })
                );
              }
            );
          } else if (status === "error") {
            setKey(key + 1);
            setError(true);
            setMsjToast(msj);
          }
          setModalStatus(false);
        }}
        type={"clientes"}
        // iconX={valueTicket === "Ver"}
      />
      <HeaderTable
        styledHead="clientes"
        clientes
        title="Clientes"
        valueBuscador={(value) => setSearchTerm(value)}
        datosButtons={buttonsHeadTable}
        onClickButton={() => {
          handleClickButtonHeader();
        }}
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={clientes}
        cRows={20}
        totalRows={itemsCount}
        pagination={true}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        // valueBuscador={valueBuscador}
        buttons={combinedButtons}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteCliente(clienteId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default Clientes;

const Styled = styled.div`
  padding: 20px;
  margin: auto;

  .styledTables {
    //height: 100vh;
  }
`;
