import axios from "./axios";
//
export const createUnidad = unidad => axios.post("/createUnidad", unidad);
export const updateUnidad = (unidad) => axios.put(`/updateUnidad`, unidad);
export const deleteUnidad = (id) => axios.delete(`/deleteUnidad/${id}`);
export const getUnidades = () => axios.get("/allUnidad");
export const getUnidadesPaginated = (page, sort, search) => axios.get("/allUnidad?page="+page+"&pageSize=20&sortBy="+sort+"&searchTerm="+search);
export const getUnidadesSearch = (page, sort, search) => axios.get("/allUnidad?sortBy="+sort+"&searchTerm="+search);


export const getUnidadesByTenantId = (id) => axios.get("/allUnidad/tenant/"+id);
export const getTipoUnidades = () => axios.get("/allTipoUnidades?pageSize=100");
export const createTipoUnidad = tipo => axios.post("/createTipoUnidades", tipo);
export const deleteTipoUnidad = (id) => axios.delete(`/deleteTipoUnidades/${id}`);
export const updateTipoUnidad = (tipo) => axios.put(`/updateTipoUnidades`, tipo);
export const getTipoUnidadesPaginated = (page, sort, search) => axios.get("/allTipoUnidades?page="+page+"&pageSize=20&sortBy="+sort+"&searchTerm="+search);
export const getTipoUnidadesSearch = (sort, search) => axios.get("/allTipoUnidades?sortBy="+sort+"&searchTerm="+search);

export const getTipoAnalisiss = () => axios.get("/allTipoAnalisis?pageSize=100");
export const createTipoAnalisis = tipo => axios.post("/createTipoAnalisis", tipo);
export const updateTipoAnalisis = (tipo) => axios.put(`/updateTipoAnalisis`, tipo);
export const deleteTipoAnalisis = (id) => axios.delete(`/deleteTipoAnalisis/${id}`);
export const getTipoAnalisisPaginated = (page, sort, search) => axios.get("/allTipoAnalisis?page="+page+"&pageSize=20&sortBy="+sort+"&searchTerm="+search);
export const getTipoAnalisisSearch = (sort, search) => axios.get("/allTipoAnalisis?sortBy="+sort+"&searchTerm="+search);


export const getAnalisis = () => axios.get("/allAnalisis?pageSize=100");
export const createAnalisis = analisis => axios.post("/createAnalisis", analisis);
export const updateAnalisis = (analisis) => axios.put(`/updateAnalisis`, analisis);
export const deleteAnalisis = (id) => axios.delete(`/deleteAnalisis/${id}`);
export const getAnalisisPaginated = (page, sort, search) => axios.get("/allAnalisis?page="+page+"&pageSize=20&searchTerm="+search+"&sortBy="+sort);
export const getAnalisisSearch = (sort, search) => axios.get("/allAnalisis?sortBy="+sort+"&searchTerm="+search);


export const getMetodos = () => axios.get("/allMetodo?pageSize=100");
export const createMetodo = metodo => axios.post("/createMetodo", metodo);
export const updateMetodo = (metodo) => axios.put(`/updateMetodo`, metodo);
export const deleteMetodo = (id) => axios.delete(`/deleteMetodo/${id}`);
export const getMetodosPaginated = (page, sort, search) => axios.get("/allMetodo?page="+page+"&pageSize=20&searchTerm="+search+"&sortBy="+sort);
export const getMetodosSearch = (sort, search) => axios.get("/allMetodo?sortBy="+sort+"&searchTerm="+search);


export const getTipoLubricantes = () => axios.get("/allTipoLubricantes?pageSize=100");
export const createTipoLubricante = tipo => axios.post("/createTipoLubricantes", tipo);
export const updateTipoLubricante = (tipo) => axios.put(`/updateTipoLubricantes`, tipo);
export const deleteTipoLubricante = (id) => axios.delete(`/deleteTipoLubricantes/${id}`);
export const getTiposLubricantesPaginated = (page, sort, search) => axios.get("/allTipoLubricantes?page="+page+"&pageSize=20&searchTerm="+search+"&sortBy="+sort);
export const getTipoLubricantesSearch = (sort, search) => axios.get("/allTipoLubricantes?sortBy="+sort+"&searchTerm="+search);


export const getLubricantes = () => axios.get("/allLubricante?pageSize=100");
export const createLubricante = lubricante => axios.post("/createLubricante", lubricante);
export const updateLubricante = (lubricante) => axios.put(`/updateLubricante`, lubricante);
export const deleteLubricante = (id) => axios.delete(`/deleteLubricante/${id}`);
export const getLubricantesPaginated = (page, sort, search) => axios.get("/allLubricante?page="+page+"&pageSize=20&searchTerm="+search+"&sortBy="+sort);
export const getLubricantesSearch = (sort, search) => axios.get("/allLubricante?sortBy="+sort+"&searchTerm="+search);


export const getComponente = () => axios.get("/allComponente");
export const createComponente = componente => axios.post("/createComponente", componente);
export const updateComponente = (componente) => axios.put(`/updateComponente`, componente);
export const deleteComponente = (id) => axios.delete(`/deleteComponente/${id}`);
export const getComponentesPaginated = (page, sort, search) => axios.get("/allComponente?page="+page+"&pageSize=20&searchTerm="+search+"&sortBy="+sort);
export const getComponenteSearch = (sort, search) => axios.get("/allComponente?sortBy="+sort+"&searchTerm="+search);


export const getTiposConjuntosAnalisis = () => axios.get("/allTipoConjuntosAnalisis?pageSize=100");
export const createTiposConjuntosAnalisis = tipo => axios.post("/createTipoConjuntosAnalisis", tipo);
export const updateTiposConjuntosAnalisis = (tipo) => axios.put(`/updateTipoConjuntosAnalisis`, tipo);
export const deleteTiposConjuntosAnalisis = (id) => axios.delete(`/deleteTipoConjuntosAnalisis/${id}`);
export const getTiposConjuntosAnalisisPaginated = (page, sort, search) => axios.get("/allTipoConjuntosAnalisis?page="+page+"&pageSize=20&searchTerm="+search+"&sortBy="+sort);
export const getTiposConjuntosAnalisisSearch = (sort, search) => axios.get("/allTipoConjuntosAnalisis?sortBy="+sort+"&searchTerm="+search);


export const getConjuntosAnalisis = () => axios.get("/allConjuntoAnalisis");
export const createConjuntosAnalisis = analisis => axios.post("/createConjuntoAnalisis", analisis);
export const updateConjuntosAnalisis = (analisis) => axios.put(`/updateConjuntoAnalisis`, analisis);
export const deleteConjuntosAnalisis = (id) => axios.delete(`/deleteConjuntoAnalisis/${id}`);
export const getConjuntosAnalisisPaginated = (page, sort, search) => axios.get("/allConjuntoAnalisis?page="+page+"&pageSize=20&searchTerm="+search+"&sortBy="+sort);
export const getConjuntosAnalisisSearch = (sort, search) => axios.get("/allConjuntoAnalisis?sortBy="+sort+"&searchTerm="+search);


export const getEstasdosFinales = () => axios.get("/allEstadoFinalMuestras");
export const createEstadosFinales = estado => axios.post("/createEstadoFinalMuestras", estado);
export const updateEstadosFinales = (estado) => axios.put(`/updateEstadoFinalMuestras`, estado);
export const deleteEstadosFinales = (id) => axios.delete(`/deleteEstadoFinalMuestras/${id}`);
export const getEstadosFinalesPaginated = (page, sort, search) => axios.get("/allEstadoFinalMuestras?page="+page+"&pageSize=20&searchTerm="+search+"&sortBy="+sort);
export const getEstasdosFinalesSearch = (sort, search) => axios.get("/allEstadoFinalMuestras?sortBy="+sort+"&searchTerm="+search);
export const getEstasdosFinalesbyId = (id) => axios.get("/lookupEstadoFinalMuestras/"+id);



export const getMensagesPredefinidos = () => axios.get("/allMensajePredefinidos");
export const createMensagesPredefinidos = mensage => axios.post("/createMensajePredefinidos", mensage);
export const updateMensagesPredefinidos = (mensage) => axios.put(`/updateMensajePredefinidos`, mensage);
export const deleteMensagesPredefinidos = (id) => axios.delete(`/deleteMensajePredefinidos/${id}`);
export const getMensagesPredefinidosPaginated = (page, sort, search) => axios.get("/allMensajePredefinidos?page="+page+"&pageSize=20&searchTerm="+search+"&sortBy="+sort);
export const getMensagesPredefinidosPaginated2 = (page, sort, search,size) => axios.get("/allMensajePredefinidos?page="+page+"&pageSize="+size+"&searchTerm="+search+"&sortBy="+sort);
export const getMensagesPredefinidosSearch = (sort, search) => axios.get("/allMensajePredefinidos?sortBy="+sort+"&searchTerm="+search);
export const getMensagesPredefinidosbyestado = (estado) => axios.get("/allMensajePredefinidos/estado/"+estado);


export const getProtocolos = () => axios.get("/allProtocolo");
export const createProtocolos = protocolo => axios.post("/createProtocolo", protocolo);
export const updateProtocolos = (protocolo) => axios.put(`/updateProtocolo`, protocolo);
export const deleteProtocolos = (id) => axios.delete(`/deleteProtocolo/${id}`);
export const getProtocolosPaginated = (page, sort, search) => axios.get("/allProtocolo?page="+page+"&pageSize=20&searchTerm="+search+"&sortBy="+sort);
export const getProtocolosPaginated2 = (page, sort, search,size) => axios.get("/allProtocolo?page="+page+"&pageSize="+size+"&searchTerm="+search+"&sortBy="+sort);
export const getProtocolosSearch = (sort, search) => axios.get("/allProtocolo?sortBy="+sort+"&searchTerm="+search);


export const getTipoEventos = () => axios.get("allTipoEventos");
export const createTipoEventos = tipo => axios.post("/createTipoEventos", tipo);
export const updateTipoEventos = (tipo) => axios.put(`/updateTipoEventos`, tipo);
export const deleteTipoEventos = (id) => axios.delete(`/deleteTipoEventos/${id}`);
export const getTiposEventosPaginated = (page, sort, search) => axios.get("/allTipoEventos?page="+page+"&pageSize=20&searchTerm="+search+"&sortBy="+sort);
export const getTipoEventosSearch = (sort, search) => axios.get("/allTipoEventos?sortBy="+sort+"&searchTerm="+search);


// export const allTenant = () => axios.get("/allTenant", {
//   headers: {
//     Authorization: "basic eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTQ1OTE4ODMsCiAgImlkIiA6ICIxIiwKICAidGVuYW50SWQiIDogIjEiLAogICJyb2wiIDogIlNVUEVSQURNSU4iCn0.hEIcL76C3j5R2Iqd-JA799imf4y0_qZ9w1BLWOIPr04"
//   }
// });

// export const createTenant = (tenant) => axios.post("/createTenant", tenant,{
//   headers: {
//     Authorization: "basic eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTQ2NjY0ODYsCiAgImlkIiA6ICIxIiwKICAidGVuYW50SWQiIDogIjEiLAogICJyb2wiIDogIlNVUEVSQURNSU4iCn0.qSW-KteqqPOZO4nLQ7WrLkh0D8IMJj2RpLiKCNckphg"
//   }
// });