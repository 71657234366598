import {
  EditOutlined,
  DeleteOutlineOutlined,
  RemoveRedEyeOutlined,
} from "@mui/icons-material";
import controlPanel from "../../assets/Icons/Buttons/Muestras/controlPanel.svg";
import excel from "../../assets/Icons/Buttons/Muestras/excel.svg";
import DiagMuestras from "../../assets/Icons/Buttons/Muestras/DiagMuestras.svg";
import Print from "../../assets/Icons/Buttons/Muestras/Print.svg";
import RenderImg from "../RenderImg/RenderImg.tsx"
export const buttonsDefault = [
  {
    titleToolTip: "Ver",
    icon: <RemoveRedEyeOutlined />,
    className: "buttonVer"
  },
  {
    titleToolTip: "Editar",
    icon: <EditOutlined />,
    className: "buttonEditar"
  },
  {
    titleToolTip: "Eliminar",
    icon: <DeleteOutlineOutlined />,
    className: "buttonEliminar"
  },
]

export const buttonsDefaultver = [
  {
    titleToolTip: "Ver",
    icon: <RemoveRedEyeOutlined />,
    className: "buttonVer"
  }
  
]

export const buttonsMuestrasOrdenesTrabajo = [
  {
    titleToolTip: "ControlPanel",
    icon: <RenderImg img={controlPanel}
    style={{ padding: "5px" }} />,
    className: "buttonMuestras"
  },
  {
    titleToolTip: "Editar",
    icon: <EditOutlined />,
    className: "buttonEditar"
  },
  {
    titleToolTip: "Excel",
    icon: <RenderImg img={excel}
    style={{ padding: "5px" }} />,
    className: "buttonMuestras"
  },
  {
    titleToolTip: "Eliminar",
    icon: <DeleteOutlineOutlined />,
    className: "buttonEliminar"
  },

]
export const buttonsMuestras = [
  {
    titleToolTip: "Ver",
    icon: <RemoveRedEyeOutlined />,
    className: "buttonVer"
  },
  {
    titleToolTip: "Diagnóstico",
    icon: <RenderImg img={DiagMuestras}
    style={{ padding: "5px" }} />,
    className: "buttonDiagnostico"
  },
  {
    titleToolTip: "Imprimir",
    icon: <RenderImg img={Print}
    style={{ padding: "5px" }} />,
    className: "buttonImprimir"
  },
  {
    titleToolTip: "Eliminar",
    icon: <DeleteOutlineOutlined />,
    className: "buttonEliminar"
  },

]

export const buttonsDashboardClientes = [
  {
    titleToolTip: "Ver",
    icon: <RemoveRedEyeOutlined />,
    className: "buttonVer"
  },
  {
    titleToolTip: "Imprimir",
    icon: <RenderImg img={Print}
      style={{ padding: "5px" }} />,
    className: "buttonImprimir"
  },
]
export const buttonsDashboardLaboratorio = [
  {
    titleToolTip: "ControlPanel",
    icon: <RenderImg img={controlPanel}
    style={{ padding: "5px" }} />,
    className: "buttonMuestras"
  },
  {
    titleToolTip: "Editar",
    icon: <EditOutlined />,
    className: "buttonEditar"
  },
  {
    titleToolTip: "Eliminar",
    icon: <DeleteOutlineOutlined />,
    className: "buttonEliminar"
  },
]
export const buttonsInfoMuestras = [
  {
    titleToolTip: "Ver",
    icon: <RemoveRedEyeOutlined />,
    className: "buttonVer"
  },
  {
    titleToolTip: "Imprimir",
    icon: <RenderImg img={Print}
      style={{ padding: "5px" }} />,
    className: "buttonImprimir"
  },
]
