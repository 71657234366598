import { Box, Typography, styled } from "@mui/material";
import { colors, fonts } from "../../../../assets/styles/theme.ts"


export const StyledTypography = styled(Typography)`
  font-family: ${fonts.primary};
  color: ${colors.blueDark};
  font-weight: 600;
  font-size: 20px ;
  
`;
export const BoxInputs = styled(Box)`
  display: flex;
  flex-direction:column;
  width: 100%;
  gap: 10px;
`;
export const BoxComponent = styled(Box)`
  display: flex;
   gap: 20px;
   align-items: center;
   @media (max-width: 768px) {
    flex-direction: column;
   }
  `;