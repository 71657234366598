
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Styled,
  CustomButton,
  Title,
  DivHeader,
  DivInputButton,
  Input,
  RenderImgStyled,
  More,
  RenderInput,
} from "./SuperAdminStyled";
import {
  EditOutlined,
  DeleteOutlineOutlined,
  RemoveRedEyeOutlined,
  Search
} from "@mui/icons-material";
import Tables from "../../../components/Tables/Tables";
import Modal from "../../../components/Modal/Modal";
import DropZone from "../../../components/DropZone/DropZone";
import { allTenant, createTenant, deleteTenant, updateTenant } from "../../../api/auth"
import { useForm } from "react-hook-form";
import { TextField } from '@mui/material';

const columns = [
  { id: "codigo", label: "Código" },
  { id: "denominacion", label: "Denominación" },
  { id: "logo", label: "Logo" },
  { id: "buttons", label: "" }
]
const buttons = [
  {
    titleToolTip: "Ver",
    icon: <RemoveRedEyeOutlined />,
    className: "buttonVer"
  },
  {
    titleToolTip: "Editar",
    icon: <EditOutlined />,
    className: "buttonEditar"
  },
  {
    titleToolTip: "Eliminar",
    icon: <DeleteOutlineOutlined />,
    className: "buttonEliminar"
  },
]
const inputGroups = [
  {
    className: "col",
    inputs: [
      { classname: "divCodigo",  label: "Código", nam: "codigo" },
      { classname: "divDenominacion",  label: "Denominación", nam: "denominacion" }
    ]
  },
  {
    className: "coll",
    inputs: [
      { classname: "divDireccion", label: "Dirección", nam: "direccion" },
      { classname: "divTelefono", label: "Teléfono", nam: "telefono" }
    ]
  },
  {
    className: "colll",
    inputs: [
      { classname: "divEmail", label: "Email", nam: "email" }
    ]
  },
  {
    className: "collll",
    inputs: [
      { classname: "divPwb", label: "Página Web", nam: "paginaWeb" },
      { classname: "divTimagen", label: "Tamaño Máximo de Imágenes", nam: "tamañoMaximoImagenes" }
    ]
  },
  {
    className: "colllll",
    dropZone: true,
    inputs: [
      { classname: "divPimagenes", label: "Path Imágenes", nam: "pathImagenes" },
      { classname: "divLmanualUsuario", label: "Link Manual del Usuario", nam: "manualUsuario" }
    ],

  },

];
const SuperAdmin = () => {
  const uniqueId = uuidv4();
  const [rows, setRows] = useState([]); 
  const [statusModal, setModalStatus] = useState(false);
  const [valueBuscador, setValueBuscador] = useState("");
  const [idTenant, setIdTenant] = useState("");
  const [idTenantt, setIdTenantt] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [dataRow, setDataRow] = useState({}); 
  const [valueTicket, setValueTicket] = useState("");
  const { register, handleSubmit, formState: { errors, isDirty, isValid }, reset } = useForm();

  const onSubmit = (data) => {
    const newData = {
      ...data,
      id: valueTicket === "Editar" ? idTenantt : (Math.floor(Math.random() * 90) + 10).toString(),
      logo: null,
      tamañoMaximoImagenes: 1024
    };
    alert(valueTicket)
    const promise = valueTicket === "Editar" ? updateTenant(newData) : createTenant(newData);
    promise
      .then(() => allTenant())
      .then((res) => {
        setRows(res.data);
        setModalStatus(false);
        setValueTicket("");
        reset();
      })
      .catch((err) => console.error(err));
  };

  const handleDeleteTenant = (tenantId) => {
    deleteTenant(tenantId)
      .then(() => {
        setIdTenant("");
        return allTenant();
      })
      .then(res => {
        setRows(res.data);
      })
      .catch(err => console.error("Error al eliminar el tenant:", err));
  };

  useEffect(() => {
    if (idTenant !== "") {
      handleDeleteTenant(idTenant)
    } else {
      allTenant()
        .then(res => {
          setRows(res.data);
        })
        .catch(err => console.error("Error al obtener datos de allTenant:", err));
    }
  }, [idTenant]);

  const handleClickButtonTable = (value, valuesRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setValueTicket(value);
      setDataRow(valuesRow);
      setIdTenantt(valuesRow?.id);
    } else {
      setIdTenant(valuesRow?.id);
    }
  };
  const RenderButtonsForm = () => {
    if (valueTicket === "Ver") return null;
    return (
      <div className="divButtons">
        <CustomButton
          label="Cancelar"
          variant="contained"
          className="buttonColorDisabled"
          onClick={handleClickCerrar}
          disableRipple
        />
        <CustomButton
          label="Confirmar"
          variant="contained"
          className="buttonColorDefault"
          disableRipple
          type="submit"
          disabled={!isDirty || !isValid}
        />
      </div>
    );
  };
  const handleClickCerrar = () => {
    setModalStatus(false);
    setValueTicket("");
    reset();
  }
  const onFileChange = (file) => {
    setSelectedFile(file);
  };

  const html = () => {
    return <form onSubmit={handleSubmit(onSubmit)}>
      <RenderInput>
        {inputGroups.map((group, index) => (
          <div key={index} className={group.className}>
            {group.inputs.map((input, inputIndex) => (
              <TextField
                {...register(input.nam, { required: true })}
                className={input.classname}
                size={"small"}
                label={input.label}
                key={inputIndex}
                defaultValue={valueTicket === "Editar" || valueTicket === "Ver" ? dataRow[input.nam] : ""}
                error={errors[input.nam] ? true : false}
                helperText={errors[input.nam] && "Este campo es requerido"}
              />
            ))}
            {group.dropZone && <DropZone onFileSelected={onFileChange} />}
          </div>
        ))}
        {RenderButtonsForm()}
      </RenderInput>
    </form>
  };

  return (
    <Styled>
      <DivHeader>
        <Title>Clientes Tenant</Title>
        <DivInputButton>
          <Input
            inputProp={true}
            positionIcon={"start"}
            placeholder={"Buscar por código, descripción, tipo"}
            icon={<Search />}
            size={"small"}
            onChange={(e) => {
              setValueBuscador(e.target.value);
            }}
            className="inputComponent"
          />
          <CustomButton
            label="Crear Nuevo Tenant"
            variant={"contained"}
            className={"buttonHeaderTable"}
            startIcon={<RenderImgStyled img={More} />}
            onClick={() => { setModalStatus(true) }}
            disableRipple
          />
        </DivInputButton>
      </DivHeader>
      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={rows}
        cRows={5}
        pagination={true}
        valueBuscador={valueBuscador}
        buttons={buttons}
        onClickButtonTable={(value, valuesRow) => { handleClickButtonTable(value, valuesRow) }}
      />
      <Modal
        html={html()}
        isOpen={statusModal}
        onClose={() => setModalStatus(false)}
        type={"default"}
        iconX={valueTicket === "Ver"}
      />
    </Styled>
  )
}

export default SuperAdmin