import styled from "styled-components";
import { colors, fonts } from "../../assets/styles/theme.ts";

export const Styled = styled.div`
  button {
    text-transform: none;
    font-family: ${fonts.primary};
    border-radius: 10px;
    font-weight: bold;
    span {
      margin: 0px;
    }
  }
  .buttonsNav,
  .buttonColorDisabled,
  .buttonColorDefault {
    text-transform: none;
    font-family: ${fonts.primary};
    color: ${colors.blueDark};
    border-radius: 10px;
    padding: 10px 15px;
    .Mui-disabled {
      background-color: ${colors.disabled};
    }
  }

  .buttonColorDefault {
    background-color: ${colors.turquoise};
    &:hover {
      background-color: ${colors.turquoise};
    }
  }
  .buttonColorDisabled {
    background-color: ${colors.lightGray};
    &:hover {
      background-color: ${colors.lightGray};
    }
  }
  .buttonColorTable {
    background-color: ${colors.blueLight};
    color: ${colors.blueDark};
    &:hover {
      background-color: ${colors.blueLight};
    }
  }
  .buttonHeaderTable {
    background-color: ${colors.blueDark};
    color: white;
    font-family: ${fonts.primary};
    &:hover {
      background-color: ${colors.blueDark};
    }
  }
`;
