import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import styled from "styled-components";
import {
  deletePuntoTomas,
  getPuntoTomasPaginated,
  getPuntoTomasSearch,
  getEquipos,
  getPlanta,
  getPuntosTomasByEquipo,
  getEquiposByPlantas,
  getPuntosTomasByPlanta,
} from "../../../../api/Clientes.js";
import {
  getComponente,
  getLubricantes,
} from "../../../../api/Configuracion.js";
import Toast from "../../../../components/Toast/Toast";
import Modal2 from "../../../../components/Modal/Modal";
import { useLocation } from "react-router-dom";
import Input from "../../../../components/Input/Input";

const columns = [
  { id: "codigo", label: "Código" },
  { id: "denominacion", label: "Denominación" },
  { id: "lubricante", label: "Lubricante" },
  { id: "componente", label: "Componente" },
  { id: "equipo", label: "Equipo" },
  { id: "buttons" },
];

const buttonsHeadTable = [
  {
    label: "Nuevo punto de toma",
    classname: "buttonHeaderTable",
  },
];

const PuntosDeToma = () => {
  const { state } = useLocation();
  const row = state ? state.row : null;

  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [puntosTomas, setPuntosTomas] = useState([]);
  const [puntosTomasId, setPuntosTomasId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [equipo, setEquipo] = useState([]);
  const [componente, setComponente] = useState([]);
  const [lubricantes, setLubricantes] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [selectedOptionequi, setSelectedOptionequi] = useState(null);
  const [selectedOptionpla, setSelectedOptionpla] = useState(null);
  const [key, setKey] = useState(1);

  useEffect(() => {
    fetchComponente();
    fetchEquipos();
    fetchLubricantes();
    fetchPlantas();

    if (row) {
      setSearchTerm(row.denominacion);
      setSelectedOptionequi({ label: row.denominacion, value: row.id });
      setSelectedOptionpla({ label: row.planta, value: row.plantaId });
    } else {
      fetchPuntosTomasPaginated(paginaActual, orderBy, searchTerm, "primera");
    }
  }, []);

  useEffect(() => {
    if (selectedOptionequi) {
      fetchPuntosTomasByEquipo(selectedOptionequi.value);
    }
  }, [selectedOptionequi]);

  useEffect(() => {
    if (selectedOptionpla) {
      fetchEquiposByPlantas(selectedOptionpla.value);
    } else {
      fetchPuntosTomasPaginated(paginaActual, orderBy, "", "");
      setEquipo([]);
    }
  }, [selectedOptionpla]);

  useEffect(() => {
    fetchPuntosTomasPaginated(paginaActual, orderBy, searchTerm, "");
  }, [paginaActual, orderBy, searchTerm]);

  const fetchPuntosTomasPaginated = async (pagina, order, search, action) => {
    const res =
      search === ""
        ? await getPuntoTomasPaginated(pagina, order, search)
        : await getPuntoTomasSearch(search);

    if (action !== "") {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
    }

    if (action === "new" && puntosTomas.length + 1 === 21) {
      setPaginaActual(res.data.totalPages);
    }

    if (action === "delete") {
      setKey(key + 1);
      setMsjToast("Punto de Toma eliminado con exito.");
      setSuccess(true);
    }

    setPuntosTomas(
      res.data.items.map((res) => {
        return {
          equipoId: res.equipo_id.id,
          lubricanteId: res.lubricante_id.id,
          id: res.id,
          codigo: res.codigo_pto,
          denominacion: res.desc_pto,
          lubricante: res.lubricante_id.description,
          componente: res.componente_id.description,
          componenteId: res.componente_id.id,
          equipo: res.equipo_id.description,
        };
      })
    );
  };

  const fetchEquiposByPlantas = async (id) => {
    const res = await getEquiposByPlantas(id);
    setEquipo(
      res.data.items.map((res) => {
        return { label: res.desc_equi, value: res.id };
      })
    );
  };

  const fetchPuntosTomasByEquipo = async (id) => {
    const res = await getPuntosTomasByEquipo(id);
    setPuntosTomas(
      res.data.items.map((res) => {
        return {
          equipoId: res.equipo_id.id,
          lubricanteId: res.lubricante_id.id,
          id: res.id,
          codigo: res.codigo_pto,
          denominacion: res.desc_pto,
          lubricante: res.lubricante_id.description,
          componente: res.componente_id.description,
          componenteId: res.componente_id.id,
          equipo: res.equipo_id.description,
        };
      })
    );
  };

  const fetchEquipos = async () => {
    const res = await getEquipos();
    setEquipo(
      res.data.items.map((res) => {
        return { label: res.desc_equi, value: res.id };
      })
    );
  };

  const fetchPlantas = async () => {
    const res = await getPlanta();
    setPlantas(
      res.data.items.map((res) => {
        return { label: res.desc_pla, value: res.id };
      })
    );
  };

  const fetchComponente = async () => {
    const res = await getComponente();
    setComponente(
      res.data.items.map((res) => {
        return { label: res.desc_comp, value: res.id };
      })
    );
  };

  const fetchLubricantes = async () => {
    const res = await getLubricantes();
    setLubricantes(
      res.data.items.map((res) => {
        return { label: res.desc_lub, value: res.id };
      })
    );
  };

  const fetchPuntosTomasByPlanta = async (id) => {
    getPuntosTomasByPlanta(id).then((res) => {
      setSelectedOptionequi("");
      setPuntosTomas(
        res.data.items.map((res) => {
          return {
            equipoId: res.equipo_id.id,
            lubricanteId: res.lubricante_id.id,
            id: res.id,
            codigo: res.codigo_pto,
            denominacion: res.desc_pto,
            lubricante: res.lubricante_id.description,
            componente: res.componente_id.description,
            componenteId: res.componente_id.id,
            equipo: res.equipo_id.description,
          };
        })
      );
    });
  };

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setPuntosTomasId(valueRow.id);
      setModalStatus2(true);
    }
  };

  const handleDelete = (id) => {
    deletePuntoTomas(id)
      .then(() => {
        setPuntosTomasId("");
        let paginaActualTemp = paginaActual;
        if (puntosTomas.length - 1 === 0 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        return fetchPuntosTomasPaginated(
          paginaActualTemp,
          "denominacion",
          searchTerm,
          "delete"
        );
      })
      .catch((err) => {
        setKey(key + 1);
        setMsjToast("Error al eliminar el Punto de Toma.");
        setError(true);
      });
  };
  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  return (
    <Styled key={key}>
      <div className="toast">
        {success ? (
          <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            key={key}
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        ) : null}
      </div>

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            setKey(key + 1);
            setSuccess(true);
            setMsjToast(msj);
            fetchPuntosTomasPaginated(paginaActual, orderBy, searchTerm, "new");
          } else if (status === "error") {
            setKey(key + 1);
            setError(true);
            setMsjToast(msj);
          }
          setModalStatus(false);
        }}
        type={"puntosDeToma"}
      />

      <div className="compexterno">
        <HeaderTable
          styledHead="clientes"
          clientes
          title="Puntos de Toma"
          datosButtons={buttonsHeadTable}
          valueBuscador={(value) => setSearchTerm(value)}
          onClickButton={() => {
            handleClickButtonHeader();
          }}
          multiInputs
          selectInput={(e) => {
            setSearchTerm(e.target.value.label);
          }}
          buttonSet={() => {
            setSearchTerm("");
          }}
          clean
          // buttonSet={()=>{}}
        />

        <div className="compinterno">
          <Input
            isClearable
            reactSelect
            stylesHeader
            placeholder={"Seleccione Planta"}
            options={plantas}
            onChange={(e) => {
              if (e === null) {
                setSelectedOptionpla("");
                setSearchTerm(""); //alert(JSON.stringify(e))
              } else {
                setSelectedOptionpla(e);
                fetchPuntosTomasByPlanta(e.value);

                // setSearchTerm(e.label);//alert(JSON.stringify(e))
              }
            }}
            value={selectedOptionpla}
          />
          <Input
            isClearable
            reactSelect
            placeholder={"Seleccione Equipo"}
            options={equipo}
            stylesHeader
            onChange={(e) => {
              if (e === null) {
                // setSelectedOptionequi("");
                // setSearchTerm("");//alert(JSON.stringify(e))
                if (selectedOptionpla !== null && selectedOptionpla !== "") {
                  fetchPuntosTomasByPlanta(selectedOptionpla.value);
                  setSelectedOptionequi("");
                } else {
                  setSelectedOptionequi("");
                  setSearchTerm(""); //alert(JSON.stringify(e))
                }
              } else {
                setSelectedOptionequi(e);
                setSearchTerm(e.label); //alert(JSON.stringify(e))
              }
            }}
            value={selectedOptionequi}
          />
        </div>
      </div>

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={puntosTomas}
        cRows={20}
        totalRows={itemsCount}
        pagination={true}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
        // onClickSwitch={(id)=> alert(id)}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDelete(puntosTomasId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default PuntosDeToma;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
    //height: 100vh;
  }
  & .compexterno {
    position: relative;
  }
  & .compinterno {
    width: 400px;
    position: absolute;
    left: 29%;
    top: 43%;
    display: flex;
    flex-direction: row;

    gap: 10px;

    & .divInput {
      width: 100%;
    }
  }
`;
