import React from 'react';
import styled from 'styled-components';
import CustomButton from '../../../../../Button/CustomButton.tsx';
import CloseIcon from '@mui/icons-material/Close';

const Card = (props) => {
  return (
    <CardStyled>
      {
        props.type === "cardUno" ? (
          <>
            <div className="divHeader">
              <div className="divNMuestra">
                <p className="label">Nro de muestra</p>
                <div className="divBody"><p className="labelBody">2738</p></div>
              </div>
              <div className="divCP">
                <p className="label">Cliente/ Planta</p>
                <div className="divBody"><p className="labelBody">Bootmart / Bootmart planta de Prueba 1</p></div>
              </div>
            </div>
            <div className="divBody">
              <div className="divDatos">
                <ul>
                  <li><span className="span">Equipo:</span> <span className="spann">Plataforma volcable (Cebada)</span></li>
                  <li><span className="span">Punto de Toma:</span> <span className="spann">M-5001-9857</span></li>
                  <li><span className="span">Lubricante:</span> <span className="spann">Shell Omala 320</span></li>
                  <li><span className="span">Extracción:</span> <span className="spann">Luis Perez - 01/02/2024 11:35</span></li>
                </ul>
              </div>
            </div>
          </>
        ) : (
          <div className="divBody">
            <div className="divDatos">
              <ul>
                <li><span className="span">Orden de Trabajo:</span> <span className="spann">Plataforma volcable (Cebada)</span></li>
                <li><span className="span">Conjunto de Análisis:</span> <span className="spann">M-5001-9857</span></li>
                <li><span className="span">Cantidad de Resultados (Cargados/totales):</span> <span className="spann">8/10</span></li>
              </ul>
            </div>
            <div className="divButtons">
              <p className="title">Estado de la Muestra</p>
              <CustomButton
                label="PENDIENTE"
                variant="contained"
                className="btnEstado"
                size="large" />
              <CustomButton
                label="Cancelar"
                startIcon={<CloseIcon />}
                classNameDiv="btnClose"
                className="buttonColorTable"
                size="small" />
            </div>
          </div>
        )
      }
    </CardStyled>
  );
};

export default Card

const CardStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .divHeader {
    display: flex;
    gap: 10px;
  }
  .divCP {
    width: 75%;
  }
  .divNMuestra,
  .divCP {
    display: flex;
    flex-direction: column;

    .label {
      font-family: "Inter", sans-serif;
      font-size: 12px;
      color: #545454;
      font-weight: 400;
      margin: 5px;
    }

    .divBody {
      background: #dbe5e8;
      border-radius: 10px;
      border: 1px solid #afccd5;
      padding: 15px;
      .labelBody {
        font-family: "Work Sans", sans-serif;
        font-weight: 600;
        font-size: 15px;
        color: #163c5b;
        margin: 0px;
      }
    }
  }
  .divBody {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .divDatos {
      display: flex;
      flex-direction: column;
      ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        li {
          margin-bottom: 10px;
          .span,
          .spann {
            font-family: "Inter", sans-serif;
          }
          .span {
            color: #545454;
            display: inline-block;
            width: 150px;
            font-size: 12px;
          }
          .spann {
            color: #163c5b;
            font-weight: bold;
            font-size: 13px;
          }
        }
      }
    }
    .divButtons {
      display: flex;
      flex-direction: column;
      .btnEstado {
        width: 173px;
        background-color: #66bbd3;
      }
      .btnClose {
        margin-left: auto;
      }
      .title {
        margin: 0px;
        font-family: "Inter", sans-serif;
        color: #545454;
        font-size: 13px;
        font-weight: 600;
        text-align: center;
      }
    }
  }
`;