import React from 'react'
import {
  DashBoardRight,
  BoxHeaderRight,
  TitleRight,
  DescriptionRight,
  BoxTable,
} from '../../DashboardClientesStyled';
import Tables from "../../../../../../components/Tables/Tables";
import { buttonsDashboardClientes } from '../../../../../../components/ButtonsTable/ButtonsTable';
const Right = () => {
  const columns = [
    { id: "muestra", label: "Muestra" },
    { id: "fechaAnalisis", label: "Fecha Análisis" },
    { id: "equipo", label: "Equipo" },
    { id: "ptodetoma", label: "Pto.de Toma" },
    { id: "estadofinal", label: "Estado Final" },
    { id: "buttons" },
  ]
  const rows = [
    {
      muestra: "2822",
      fechaAnalisis: "21/10/2023",
      equipo: "Motorreductor 4 Giracleur Cuba 2",
      ptodetoma: "01-0002-0218-0001",
      estadofinal: "Peligro",
    },
    {
      muestra: "2822",
      fechaAnalisis: "21/10/2023",
      equipo: "Motorreductor 4 Giracleur Cuba 2",
      ptodetoma: "01-0002-0218-0001",
      estadofinal: "Peligro",
    },
    {
      muestra: "2822",
      fechaAnalisis: "21/10/2023",
      equipo: "Motorreductor 4 Giracleur Cuba 2",
      ptodetoma: "01-0002-0218-0001",
      estadofinal: "Peligro",
    },
    {
      muestra: "2822",
      fechaAnalisis: "21/10/2023",
      equipo: "Motorreductor 4 Giracleur Cuba 2",
      ptodetoma: "01-0002-0218-0001",
      estadofinal: "Peligro",
    },
    {
      muestra: "2822",
      fechaAnalisis: "21/10/2023",
      equipo: "Motorreductor 4 Giracleur Cuba 2",
      ptodetoma: "01-0002-0218-0001",
      estadofinal: "Peligro",
    },
    {
      muestra: "2822",
      fechaAnalisis: "21/10/2023",
      equipo: "Motorreductor 4 Giracleur Cuba 2",
      ptodetoma: "01-0002-0218-0001",
      estadofinal: "Peligro",
    },
    {
      muestra: "2822",
      fechaAnalisis: "21/10/2023",
      equipo: "Motorreductor 4 Giracleur Cuba 2",
      ptodetoma: "01-0002-0218-0001",
      estadofinal: "Peligro",
    },
    {
      muestra: "2822",
      fechaAnalisis: "21/10/2023",
      equipo: "Motorreductor 4 Giracleur Cuba 2",
      ptodetoma: "01-0002-0218-0001",
      estadofinal: "Peligro",
    },
  ]
  const handleClickButonTable =(value,valueRow)=>{
    console.log(value,valueRow);
  }
  return (
    <DashBoardRight>
      <BoxHeaderRight>
        <TitleRight>Últimos Informes de Muestras Recibidos</TitleRight>
        <DescriptionRight>Aquí podrá consultar los últimos 10 informes de muestras,
          para poder acceder a la consulta completa de informes, presione la opción “Informes de Muestras” que se encuentra en la cabecera
        </DescriptionRight>
      </BoxHeaderRight>
      <BoxTable>
        <Tables
          typeTable={"prop"}
          columns={columns}
          rows={rows}
          cRows={6}
          pagination={true}
          buttons={buttonsDashboardClientes}
          onClickButtonTable={(value, valuesRow) => { handleClickButonTable(value, valuesRow) }}
        />
      </BoxTable>
    </DashBoardRight>
  )
}

export default Right