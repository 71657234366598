import axios from "./axios";

export const loginUser = user => axios.post("/login", user);
//
export const createTenant = tenant => axios.post("/createTenant", tenant);
export const allTenant = () => axios.get("/allTenant");
export const updateTenant = (tenantUpdate) => axios.put(`/updateTenant`, tenantUpdate);
export const deleteTenant = (id) => axios.delete(`/deleteTenant/${id}`);
//
export const lookupUser = (id) => axios.get(`/lookupUser/${id}`);
export const users = () => axios.get("/allUsers");
export const updateUser = (userUpdate) => axios.put("/updateUser", userUpdate );


// export const allTenant = () => axios.get("/allTenant", {
//   headers: {
//     Authorization: "basic eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTQ1OTE4ODMsCiAgImlkIiA6ICIxIiwKICAidGVuYW50SWQiIDogIjEiLAogICJyb2wiIDogIlNVUEVSQURNSU4iCn0.hEIcL76C3j5R2Iqd-JA799imf4y0_qZ9w1BLWOIPr04"
//   }
// });

// export const createTenant = (tenant) => axios.post("/createTenant", tenant,{
//   headers: {
//     Authorization: "basic eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTQ2NjY0ODYsCiAgImlkIiA6ICIxIiwKICAidGVuYW50SWQiIDogIjEiLAogICJyb2wiIDogIlNVUEVSQURNSU4iCn0.qSW-KteqqPOZO4nLQ7WrLkh0D8IMJj2RpLiKCNckphg"
//   }
// });