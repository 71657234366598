import axios from "axios";

const baseUrl = "http://localhost:9000";
const baseUrll ="http://localhost:9000";
const BaseURL = process.env.REACT_APP_BASEURL;

export const tokenAxios = (valueToken)=>{
  return valueToken ? valueToken : "";
}

const instance = axios.create({
  baseURL: BaseURL ,
  withCredentials: false,
});

instance.interceptors.request.use(
  config => {
    const token = tokenAxios(localStorage.getItem('token'));
    if (token) {
      config.headers.Authorization = `basic ${token}`;
     //config.headers.Authorization = 'basic eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTQ2ODA2NDIsCiAgImlkIiA6ICIyMSIsCiAgInRlbmFudElkIiA6ICIxIiwKICAicm9sIiA6ICJTVVBFUkFETUlOIgp9.eE2E16j9VO-AL408QTzmvCTFXHrbmMzcwLtoolZtOKA';
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;