import React from 'react';
import RenderImg from '../RenderImg/RenderImg.tsx';
import LimsOil from "../../assets/Login/LimsOil.png";
import { Styled, DivBody, DivImgFooter, LabelFooter } from './FooterStyled';
const Footer = () => {
  return (
    <Styled>
      <DivBody>
        <DivImgFooter>
          <RenderImg img={LimsOil} alt="skf" className="img" />
        </DivImgFooter>
        <LabelFooter>Software de Gestión de Laboratorios de Análisis de Lubricantes
          <span className="labelLink">Power by www.limsoil.com <span className="span">Todos los derechos reservados</span> </span>
        </LabelFooter>
      </DivBody>
    </Styled>
  )
}

export default Footer
