import React, { useState } from 'react';
import Tables from '../../../../components/Tables/Tables';
import HeaderTable from '../../../../components/HeaderTable/HeaderTable';
import Modal from "../../../../components/Modal/Modal";
import { buttonsMuestrasOrdenesTrabajo } from '../../../../components/ButtonsTable/ButtonsTable';
import styled from 'styled-components';


const columns = [
  { id: "fechaProgr", label: "Fecha Progr." },
  { id: "codigo", label: "Código" },
  { id: "lote", label: "Lote" },
  { id: "denominacion", label: "Denominación" },
  { id: "cliente", label: "Cliente" },
  { id: "planta", label: "Planta" },
  { id: "estado", label: "Estado" },
  { id: "buttons" },
]
const rows = [
  {
    fechaProgr: "fecha",
    codigo: "codigo",
    lote: "lote",
    denominacion: "denominacion",
    cliente: "cliente",
    planta: "planta",
    estado: "estado"
  }
]

const buttonsHeadTable = [
  {
    label: "Nueva orden de trabajo",
    classname: "buttonHeaderTable"
  },
]
const datosInputsHeaderTable = [
  {
    inputSelect: true,
    placeholder:"placeholder 1",
    datosSelect: [{ label: "primero", value: "1" },
    { label: "segundo", value: "2" },
    { label: "tercero", value: "3" }]
  },
  {
    inputSelect: true,
    placeholder:"placeholder 2",
    datosSelect: [{ label: "primero", value: "1" },
    { label: "segundo", value: "2" },
    { label: "tercero", value: "3" }]
  },
  {
    inputSelect: true,
    placeholder:"placeholder 3",
    datosSelect: [{ label: "primero", value: "1" },
    { label: "segundo", value: "2" },
    { label: "tercero", value: "3" }]
  },
]
const OrdenesDeTrabajo = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    }
  }
  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  }
  return (
    <Styled>
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={() => setModalStatus(false)}
        type={"ordenesTrabajo"}
      />
      <HeaderTable
        styledHead="muestras"
        muestras
        multiInputs
        datosInputsHeaderTable={datosInputsHeaderTable}
        title="Ordenes de Trabajo"
        datosButtons={buttonsHeadTable}
        valueBuscador={(value) => console.log(value)}
        selectInput={(value) => console.log(value)}
        onClickButton={() => { handleClickButtonHeader() }}
        clean
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={rows}
        cRows={50}
        pagination={true}
        // valueBuscador={valueBuscador}
        buttons={buttonsMuestrasOrdenesTrabajo}
        onClickButtonTable={(value, valuesRow) => { handleClickButonTable(value, valuesRow) }}
      />

    </Styled>
  )
}

export default OrdenesDeTrabajo;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables{
    height: 100vh;
  }
`