import React, { useReducer, useState, useEffect } from 'react';
import { StyledModalcomponent } from '../../../Styled.jsx';
import Input from '../../../../Input/Input.jsx';
import CustomButton from '../../../../Button/CustomButton.tsx';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createAnalisis, getTipoAnalisiss, getUnidades, updateAnalisis, getMetodos, getUnidadesPaginated } from '../../../../../api/Configuracion';
import Toast from '../../../../Toast/Toast.jsx';
import Preloader from '../../../../Preloader/Preloader.jsx';


const Analisis = (props) => {
  const propsAnalisis = (datosUnidades) => {
    console.log(datosUnidades?.datos)
    const ver = datosUnidades?.button === "ver" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos }
  }

  const { ver, editar, datos } = propsAnalisis(props.datos);
  const [unidades, setUnidades] = useState([]);
  const [metodos, setMetodos] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState('');
  const [tiposAnalisis, setTiposAnalisis] = useState([]);
  const [formCompleto, setFormCompleto] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptiontpana, setSelectedOptiontpana] = useState(null);
  const [selectedOptionmetodo, setSelectedOptionmetodo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    id: datos.id ?? '',
    codigo: datos.codigo ?? '',
    descripcion: datos.descripcion ?? '',
    unidad: datos.unidadid ?? '',
    metodo: datos.metodoid ?? '',
    tipoDeAnalisis: datos.tipoid ?? '',
    habilitado: datos.habilitado === "SI" || datos.habilitado === "ACTIVE" ? true : false
  });

  useEffect(() => {
    const fetchUnidades = async () => {
      const res = await getUnidades();
      const unidades = res?.data?.items.map(item => ({
        value: item.id,
        label: item.desc_uni,
      }));
      setUnidades(unidades);
      if (datos) {
        const matchOption = unidades.find(option => option.label === datos.unidad);
        if (matchOption) {
          setSelectedOption(matchOption);
        } else if (unidades.length > 0 && editar) {
          setSelectedOption(unidades[0]);
        }
      } else if (unidades.length > 0 && editar) {
        setSelectedOption(unidades[0]);
      }
    };

    const fetchtpAnalisis = async () => {
      const res = await getTipoAnalisiss();
      const tipoanalisis = res?.data?.items.map(item => ({
        value: item.id,
        label: item.desc_tpana,
      }));
      // alert(JSON.stringify(tipoanalisis))
      setTiposAnalisis(tipoanalisis);
      if (datos) {
        const matchOption = tipoanalisis.find(option => option.label === datos.tipo);
        if (matchOption) {
          setSelectedOptiontpana(matchOption);
        } else if (tipoanalisis.length > 0 && editar) {
          setSelectedOptiontpana(tipoanalisis[0]);
        }
      } else if (tipoanalisis.length > 0 && editar) {
        setSelectedOptiontpana(tipoanalisis[0]);
      }
    };

    const fetchtMetodo = async () => {
      const res = await getMetodos();
      const tipoanalisis = res?.data?.items.map(item => ({
        value: item.id,
        label: item.desc_met,
      }));

      setMetodos(tipoanalisis);
      if (datos) {
        const matchOption = tipoanalisis.find(option => option.label === datos.metodo);
        if (matchOption) {

          setSelectedOptionmetodo(matchOption);
        } else if (tipoanalisis.length > 0 && editar) {
          setSelectedOptionmetodo(unidades[0]);
        }
      } else if (tipoanalisis.length > 0 && editar) {
        setSelectedOptionmetodo(unidades[0]);
      }
    };

    fetchUnidades();
    fetchtpAnalisis();
    fetchtMetodo();



  }, []);

  useEffect(() => {
    if (selectedOption) {
      setForm({ type: 'SET_FIELD', field: "unidad", value: selectedOption.value });
    }
  }, [selectedOption]);

  useEffect(() => {
    if (selectedOptiontpana) {
      setForm({ type: 'SET_FIELD', field: "tipoDeAnalisis", value: selectedOptiontpana.value });
    }
  }, [selectedOptiontpana]);
  useEffect(() => {
    if (selectedOptionmetodo) {
      setForm({ type: 'SET_FIELD', field: "metodo", value: selectedOptionmetodo.value });
    }
  }, [selectedOptionmetodo]);

  useEffect(() => {
    const campoValido = campo => form[campo] !== '' && (campo !== 'habilitado' || form[campo]);
    const camposaValidar = ['codigo', 'descripcion', 'unidad', 'metodo', 'tipoDeAnalisis', 'habilitado'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === 'checkbox' ? checked : value;
      setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
      if (name === "tipoDeAnalisis") {
        setSelectedOptiontpana(e);
        setForm({ type: 'SET_FIELD', field: name, value: value });
      } else if (name === "metodo") {
        setSelectedOptionmetodo(e);
        setForm({ type: 'SET_FIELD', field: name, value: value });
      } else if (name === "unidad") {
        setSelectedOption(e);
        setForm({ type: 'SET_FIELD', field: name, value: value });
      }

      // setSelectedOption(e);
      // alert(JSON.stringify(e))
      // setForm({ type: 'SET_FIELD', field: name, value: value });
    }
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    let formattedData = {
      "id": form.id,
      "codigo_ana": form.codigo,
      "desc_ana": form.descripcion,
      "tipo_id": form.tipoDeAnalisis.toString(),
      "unidad_id": form.unidad.toString(),
      "metodo_id": form.metodo.toString(),
      "created_by": "dasfdasd",
      "created_date": new Date(),
      "status": form.habilitado ? "SI" : "NO",
      "tenantId": 1,
      "eliminado": false

    }
    // alert(JSON.stringify(formattedData))

    if (editar) {
      updateAnalisis(formattedData).then(res => {
        setIsLoading(false);
        props.onClick({ msj: "Análisis modificado con éxito", status: "success" });

      }).catch((err) => {
        setIsLoading(false);
        props.onClick({ msj: "No se Pudo editar el Análisis", status: "error" });
      });
    } else {
      createAnalisis(formattedData).then(res => {
        setIsLoading(false);
        props.onClick({ msj: "Análisis creado con exito", status: "success" });
      }).catch((err) => {
        setIsLoading(false);
        props.onClick({ msj: "No se Pudo crear el Análisis", status: "error" });
      });
    }
  }

  const titulo = () => {
    const titulo = ver ? "Ver análisis" : editar ? "Editar análisis" : "Crear o editar Análisis";
    return titulo
  }
  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!editar ? !formCompleto : false}
          />
        )}
      </>
    );
  };

  return (
    <StyledModalcomponent className="divModalItem">
      <Preloader title="Cargando..." subtitle={"Espere unos Segundos por favor..."} loading={isLoading} />

      <div classname="toast" >
        {success ? <Toast title={msjToast} close={() => setSuccess(false)} /> : null}
        {error ? <Toast type={"error"} title={"Error al " + (editar ? "modificar" : "crear") + "la unidad ?"} close={() => setError(false)} /> : null}</div>

      <div className="analisis">
        <div className="divLabel">
          <p className="titulo"> {titulo()}</p>
        </div>

        <div className="divInputsItem">
          <form className="formulario" onSubmit={handleSubmit} >
            <div className="divPrimerInput">
              <Input
                value={form.codigo}
                label="Código"
                className="inputCodigo input"
                variant="outlined"
                size={"medium"}
                name="codigo"
                disabled={ver}
                onChange={handleChange} />
              <FormControlLabel
                control={<Switch name="habilitado" disabled={ver} checked={form.habilitado} onChange={handleChange} />}
                label="Habilitar"
              />
            </div>

            <Input
              value={form.descripcion}
              label="Descripción"
              className="inputDesc input"
              variant="outlined"
              size={"medium"}
              name="descripcion"
              disabled={ver}

              onChange={handleChange}
            />

            <Input
              reactSelect
              options={unidades}
              value={selectedOption}
              onChange={(e) => { handleChange(e, "unidad") }}
              isDisabled={ver && true}
              name="unidad"
              placeholder="Selecciona la unidad"
            />
            <Input
              reactSelect
              name="metodo"
              options={metodos}
              value={selectedOptionmetodo}
              onChange={(e) => { handleChange(e, "metodo") }}
              isDisabled={ver && true}
              placeholder="Selecciona el Método"
            />
            <Input
              name="tipoDeAnalisis"
              reactSelect
              options={tiposAnalisis}
              value={selectedOptiontpana}
              onChange={(e) => { handleChange(e, "tipoDeAnalisis") }}
              isDisabled={ver && true}
              placeholder="Selecciona el Tipo"
            />
            <div className="divButtonsItem">
              {renderButtons()}
            </div>

          </form>



        </div>
      </div>
    </StyledModalcomponent>
  )
}

export default Analisis