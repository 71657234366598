import React from 'react';
import {
  Styled,
  HeaderDashboard,
  BoxLabel,
  TitleHeader,
  DescriptionHeader,
  BoxImg,
  Body,
} from './DashboardClientesStyled.js';
import Left from './Items/Left/Left.jsx';
import Right from './Items/Right/Right.jsx';
import DashboardFooter from './Items/Footer/DashboardFooter.jsx'
import { Divider } from "@mui/material";
import RenderImg from "../../../../components/RenderImg/RenderImg.tsx";
import imgIndex from "../../../../assets/ImgIndex.png";

const DashboardClientes = () => {
  return (
    <Styled>
      <HeaderDashboard>
        <BoxLabel>
          <TitleHeader>Bienvenid@ a LimsOIL </TitleHeader>
          <DescriptionHeader>
            Software de Gestión de Laboratorios
            de Análisis de Lubricantes
          </DescriptionHeader>
        </BoxLabel>
        <BoxImg>
          <RenderImg img={imgIndex} width='100%' height='auto' style={{ objectFit: "cover" }} />
        </BoxImg>
      </HeaderDashboard>
      <Body>
        <Left />
        <Divider orientation="vertical" flexItem sx={{border:"1px solid #66BBD3"}} />
        <Right />
      </Body>
      <Divider sx={{border:"1px solid #66BBD3"}} />
      <DashboardFooter />
    </Styled>
  )
}

export default DashboardClientes



