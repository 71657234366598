import styled from "styled-components";
import { Alert, Box, Typography } from "@mui/material";
import { colors, fonts } from "../../assets/styles/theme.ts";

export const AlertStyled = styled(Alert)`
 &&{
  color : ${colors.white};
  font-family: ${fonts.secondary};
  font-weight: 700;
  font-size: 16px;
  display:flex;
  align-items: center;
  height: 28px;
  }
`;
export const BoxActionStyled = styled(Box)`
  &&{
    display: flex;
    align-items: baseline;
  }
`
export const TypographyTitle = styled(Typography)`
  &&{
    color : ${colors.white};
    font-family: ${fonts.secondary};
    font-weight: 700;
    font-size: 16px; 
    max-width: 327px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
  }
`