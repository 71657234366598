import React from 'react';
import {
  Styled,
  HeaderDashboard,
  BoxLabel,
  TitleHeader,
  DescriptionHeader,
  BoxImg,
  Body,
} from './DashboardLaboratorioStyled';
import { Divider } from "@mui/material";
import RenderImg from '../../../../components/RenderImg/RenderImg.tsx';
import imgIndex from "../../../../assets/ImgIndex.png";
import LeftDashboardLaboratorio from "./items/LeftDashboardLaboratorio.jsx";
import RightDashboardLaboratorio from "./items/RightDashboardLaboratorio.jsx";
import DashboardFooterLaboratorio from './items/DashboardFooterLaboratorio.jsx';
const DashboardLaboratorio = () => {
  return (
    <Styled> <HeaderDashboard>
      <BoxLabel>
        <TitleHeader>Bienvenid@ a LimsOIL </TitleHeader>
        <DescriptionHeader>
          Software de Gestión de Laboratorios
          de Análisis de Lubricantes
        </DescriptionHeader>
      </BoxLabel>
      <BoxImg>
        <RenderImg img={imgIndex} width='100%' height='auto' style={{ objectFit: "cover" }} />
      </BoxImg>
    </HeaderDashboard>
      <Body>
        <LeftDashboardLaboratorio />
         <Divider orientation="vertical" flexItem sx={{ border: "1px solid #66BBD3" }} />
        <RightDashboardLaboratorio />
      </Body>
      <Divider sx={{border:"1px solid #66BBD3"}} />
      <DashboardFooterLaboratorio />
    </Styled>
  )
}

export default DashboardLaboratorio