import { Box, Typography, styled } from "@mui/material";
import { colors,fonts } from "../../assets/styles/theme.ts";

export const StyledModalNav = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent; 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  .modalNav{
    flex-wrap: wrap;
    position: absolute;
    z-index: 99999;
    top: 10%;
    margin: auto;
    width: 50%;
    height: auto;
    display: flex;
    border-radius: var(--1, 8px);
    border: var(--borderRadius, 4px) solid #66bbd3;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
    background-color: #dbe5e8;
    padding: 5px 50px;
    justify-content: space-between;
  }
  .divHeaderModal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: 60px;
    padding: 15px 0px;
  }
  .divInput {
    width: 50%;
  }
  .divIconCerrar {
    position: absolute;
    top: 15px;
    right: 15px;
    .iconCerrar {
      cursor: pointer;
    }
  }
  .divButtonModalIcon {
    align-items: start;
    display: flex;
    justify-content: space-between;
    gap: 14px;
    padding: 5px 17px 0 11px;
    width: 45.5%;
  }
  .divLabel {
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
    .label {
      font: 600 20px Inter, sans-serif;
      margin: 2px 0px;
      padding: 0px;
      text-align: start;
      color: #163c5b;
    }
    .sub {
      color: var(--base-titulo-input, #545454);
      font: 400 11px Inter, sans-serif;
    }
  }
  @media (max-width: 768px) {
    .modalNav 
    {
      width: 80%;
      height: auto;
      flex-wrap: nowrap;
      flex-direction: column;
      top: 5%;
    }
    .divHeaderModal{
      padding:0px;
      margin: 0px;
    }
    .divInput{
      width: 100%;
    }
    .divButtonModalIcon {
      width: 100%;
      padding: 5px 0px;
    }
    /* .divModalButtons {
      padding: 0px 10px;
      width: 50%;
    } */
  }
`;
export const StyledModal = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  .modalDefault{
    border: var(--borderRadius, 4px) solid ${colors.turquoise};
    background-color: ${colors.lightGray};
    box-shadow: 0px 4px 6px 0px #00000040;
    position: absolute;
    border-radius: 8px;
  @media screen and (max-width: 768px) {
    width: 100% !important;
    >div>div>div{
      display:flex;
      flex-direction: column;
      >div{
        width: 100% !important;
      }
    }
  }
  }
  
`;


//Default
export const StyledDefault = styled(Box)`
  &&{
    background-color: ${colors.white};
    padding: 50px 20px 10px 20px;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    width: auto;
  }
`
export const DivContent = styled(Box)`
  width: 90%;
  margin: auto;
`
export const DivHeader = styled(Box)`
  border-radius:10px;
  background-color: ${colors.lightGray};
  padding: 5px;
  text-align: center;
`;
export const Title = styled(Typography)`
  &&{
    font-family: ${fonts.primary};
    color: ${colors.blueDark};
    font-weight: 600;
  }
`;
export const DivIconClose = styled(Box)`
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
`  

// const StyledModalNav = styled.div`
// display:flex;
// position: fixed;
// top: 0;
// left: 0;
// width: 100%;
// height: 100%;
// background-color: transparent;
// z-index: 1000; 
// justify-content: center;
// //Responsive
// @media (max-width: 1370px) {
//   .divModalButtons{
//     padding:0px 10px;
//     width: 50%;
//   }
// }
// @media (max-width: 768px) {
//   width:auto;
//   flex-direction: column;
//   padding:0px;
//   .divButtonModalIcon{
//     width:auto;
//   }
//   .divLabel{
//     .label,.sub{
//        font:normal; 
//     }
//   }
// }
// `

// const StyledModal = styled.div`
// position: fixed;
// top: 0;
// left: 0;
// width: 100%;
// height: 100%;
// background-color: rgba(0, 0, 0, 0.5);
// z-index: 1000;
// `