import { Box, Typography, styled } from "@mui/material";
import { colors, fonts } from "../../../assets/styles/theme.ts";

export const Styled = styled(Box)`
  display:flex;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 768px) {
    gap: 0px;
    padding: 0px;
  }
`

export const DivImg = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  .img{
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: inherit;
    cursor: pointer;
  }
  &:hover {
      background-color: ${colors.disabled};
  }
  @media screen and (max-width: 768px) {
    margin-left: -10px;
  }
`

export const DivLabel = styled(Box)``

export const Title = styled(Typography)`
  &&{
    color: ${colors.blueDark};
    font-family: ${fonts.primary};
    font-size: 16px;
    font-weight: 500;
  }
`

export const DivIconLogout = styled(Box)`
  padding-top: 5px; 
  padding-left: 5px;
  cursor: pointer;
`