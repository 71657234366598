import axios from "./axios";
//
export const createCliente = (cliente) => axios.post("/createCliente", cliente);
export const updateCliente = (cliente) => axios.put(`/updateCliente`, cliente);
export const deleteCliente = (id) => axios.delete(`/deleteCliente/${id}`);
export const getClientes = () => axios.get("/allCliente");
export const getClientesPaginated = (page, sort, search) =>
  axios.get(
    "/allCliente?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );

export const getClientesSearch = (search) =>
  axios.get("/allCliente?searchTerm=" + search);

export const createPlanta = (cliente) => axios.post("/createPlanta", cliente);
export const updatePlanta = (cliente) => axios.put(`/updatePlanta`, cliente);
export const deletePlanta = (id) => axios.delete(`/deletePlanta/${id}`);
export const getPlanta = () => axios.get("/allPlanta");
export const getPlantaPaginated = (page, sort, search) =>
  axios.get(
    "/allPlanta?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );
export const getPlantaSearch = (search) =>
  axios.get("/allPlanta?searchTerm=" + search);
///allPlanta/cliente/:clienteId
export const getPlantabyCliente = (id, page, sort, search) =>
  axios.get(
    "/allPlanta/cliente/" +
      id +
      "?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );
export const getPlantabyCliente2 = (id) =>
  axios.get("/allPlanta/cliente/" + id);

export const createEquipos = (cliente) => axios.post("/createEquipo", cliente);
export const updateEquipos = (cliente) => axios.put(`/updateEquipo`, cliente);
export const deleteEquipos = (id) => axios.delete(`/deleteEquipo/${id}`);
export const getEquipos = () => axios.get("/allEquipo");
export const getEquiposPaginated = (page, sort, search) =>
  axios.get(
    "/allEquipo?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );
export const getEquiposSearch = (search) =>
  axios.get("/allEquipo?&searchTerm=" + search);
export const getEquiposByPlantas = (id) => axios.get("/allEquipo/planta/" + id);
export const getEquiposByClientes = (id) =>
  axios.get("/allEquipo/cliente/" + id); ///planta/"+id);cuando ande el endpoint

export const createPuntoTomas = (cliente) =>
  axios.post("/createPuntoTomas", cliente);
export const updatePuntoTomas = (cliente) =>
  axios.put(`/updatePuntoTomas`, cliente);
export const deletePuntoTomas = (id) => axios.delete(`/deletePuntoTomas/${id}`);
export const getPuntoTomas = () => axios.get("/allPuntoTomas");
export const getPuntoTomasPaginated = (page, sort, search) =>
  axios.get(
    "/allPuntoTomas?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );
export const getPuntoTomasSearch = (search) =>
  axios.get("/allPuntoTomas?searchTerm=" + search);
export const getPuntosTomasByPlanta = (id) =>
  axios.get("/allPuntoTomas?idPlanta=" + id);
export const getPuntosTomasByEquipo = (id) =>
  axios.get("/allPuntoTomas/equipo/" + id);

export const getIntervencion = () => axios.get("/allIntervencion");
export const getIntervecionPaginated = (page, sort, search) =>
  axios.get(
    "/allIntervencion?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );
export const getIntervecionSearch = (search) =>
  axios.get("/allIntervencion?searchTerm=" + search);
export const createIntervencion = (cliente) =>
  axios.post("/createIntervencion", cliente);
export const updateIntervecion = (cliente) =>
  axios.put(`/updateIntervencion`, cliente);
export const deleteIntervecion = (id) =>
  axios.delete(`/deleteIntervencion/${id}`);
export const getIntervencionByEquipo = (id) =>
  axios.get("/allIntervencion?idEquipo=" + id); //ver q el tenantid lo saque el back
export const getIntervencionByCliente = (id) =>
  axios.get("/allIntervencion?idCliente=" + id); //ver q el tenantid lo saque el back

export const getProveedores = () => axios.get("/allProveedor");
export const getProveedorePaginated = (page, sort, search) =>
  axios.get(
    "/allProveedor?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );
export const createProveedores = (cliente) =>
  axios.post("/createProveedor", cliente);
export const updateProveedores = (cliente) =>
  axios.put(`/updateProveedor`, cliente);
export const deleteProveedor = (id) => axios.delete(`/deleteProveedor/${id}`);

// export const allTenant = () => axios.get("/allTenant", {
//   headers: {
//     Authorization: "basic eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTQ1OTE4ODMsCiAgImlkIiA6ICIxIiwKICAidGVuYW50SWQiIDogIjEiLAogICJyb2wiIDogIlNVUEVSQURNSU4iCn0.hEIcL76C3j5R2Iqd-JA799imf4y0_qZ9w1BLWOIPr04"
//   }
// });

// export const createTenant = (tenant) => axios.post("/createTenant", tenant,{
//   headers: {
//     Authorization: "basic eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTQ2NjY0ODYsCiAgImlkIiA6ICIxIiwKICAidGVuYW50SWQiIDogIjEiLAogICJyb2wiIDogIlNVUEVSQURNSU4iCn0.qSW-KteqqPOZO4nLQ7WrLkh0D8IMJj2RpLiKCNckphg"
//   }
// });
