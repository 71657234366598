import React, { useReducer, useState, useEffect } from 'react';
import { StyledModalcomponent } from '../../../Styled.jsx';
import Input from '../../../../Input/Input.jsx';
import CustomButton from '../../../../Button/CustomButton.tsx';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Preloader from '../../../../Preloader/Preloader.jsx';
import {  createTiposConjuntosAnalisis, updateTiposConjuntosAnalisis } from '../../../../../api/Configuracion';
import Toast from '../../../../Toast/Toast.jsx';
const TipoCEAnalisis = (props) => {
  const propsTipoCEAnalisis = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos }
  }
  const { ver, editar, datos } = propsTipoCEAnalisis(props.datos);
  const [isLoading, setIsLoading] = useState(false);
  const [formCompleto, setFormCompleto] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState('');
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    id: datos.id ?? '',
    codigo: datos.codigo ?? '',
    descripcion: datos.descripcion ?? '',
    habilitado: datos.habilitado === "SI" || datos.habilitado === "ACTIVE"  ? true : false
  });

  useEffect(() => {
    const campoValido = campo => form[campo] !== '' && (campo !== 'habilitado' || form[campo]);

    const camposaValidar = ['codigo', 'descripcion', 'habilitado'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);


  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (ver) {
      return
    }
    const fieldValue = name === 'habilitado' ? checked : value;
    setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
  };

 
  const titulo = () => {
    const titulo = ver ? "Tipo de Conjunto de Análisis" : editar ? "Editar Tipo de Conjunto de Análisis" : "Crear o editar Tipo de Conjunto de Análisis";
    return titulo
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formattedData =       
      { "id": form.id,
        "codigo_tpcana": form.codigo.toString(),
        "desc_tpcana": form.descripcion,
        "abrev_tpcana": "nc",
        "created_by": "cabs",
        "created_date": new Date(),        
        "status": form.habilitado?"SI":"NO",
        "tenantId":1,//sacar tenant
        "eliminado": false
      }
    
  
 // alert(JSON.stringify(formattedData))

  if(editar){
    updateTiposConjuntosAnalisis(formattedData).then(res => {
      setIsLoading(false);       
      props.onClick({msj:"Tipo Conjunto análisis modificado con éxito", status:"success"});     
    }).catch((err)=>{
      setIsLoading(false);       
      props.onClick({msj:"No se Pudo editar el Tipo Conjunto análisis", status:"error"});
    });
  }else{ 
    createTiposConjuntosAnalisis(formattedData).then(res => {
      setIsLoading(false);
      props.onClick({msj:"Tipo Conjunto análisis creado con éxito", status:"success"});         
    }).catch((err)=>{
      setIsLoading(false);       
      props.onClick({msj:"No se Pudo editar el Tipo Conjunto análisis", status:"error"});
    });
  }
  }

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!editar ? !formCompleto : false}
          />
        )}
      </>
    );
  };
  return (
    <StyledModalcomponent>
  <Preloader title="Cargando..." subtitle={"Espere unos Segundos por favor..."} loading={isLoading} />
    
    <div classname="toast" >      {success? <Toast title={msjToast} close={() => setSuccess(false)}/> : null}
      {error? <Toast type={"error"} title={msjToast} close={() => setError(false)}/> : null}
    </div> 
      <div className="tipoConjuntoAnalisis">
        <div className="divLabel">
          <p className="titulo">{titulo()}</p>
        </div>
        <div className="divInputsItem">
          <form className="formulario" onSubmit={handleSubmit}>
            <div className="divPrimerInput">
              <Input
                value={form.codigo}
                label="Código"
                className="inputCodigo input"
                variant="outlined"
                size={"medium"}
                name="codigo"
                disabled={ver}
                onChange={handleChange}
              />
              <FormControlLabel
                control={<Switch name="habilitado" disabled={ver} checked={form.habilitado} onChange={handleChange} />}
                label="Habilitar"
              />
            </div>

            <Input
              value={form.descripcion}
              label="Descripción"
              className="inputDesc input"
              variant="outlined"
              size={"medium"}
              name="descripcion"
              onChange={handleChange}
              disabled={ver}
            />
            <div className="divButtonsItem">
              {renderButtons()}
            </div>
          </form>
        </div>
      </div>
    </StyledModalcomponent>
  )
}

export default TipoCEAnalisis