import React from 'react'
import {
  DashBoardRight,
  BoxHeaderRight,
  TitleRight,
  DescriptionRight,
  BoxTable,
} from '../DashboardLaboratorioStyled';
import Tables from "../../../../../components/Tables/Tables";
import { buttonsDashboardLaboratorio } from '../../../../../components/ButtonsTable/ButtonsTable';
const RightDashboardLaboratorio = () => {
  const columns = [
    { id: "fechaProgr", label: "Fecha Progr." },
    { id: "codigo", label: "Código" },
    { id: "lote", label: "Lote" },
    { id: "cliente", label: "Cliente" },
    { id: "planta", label: "Planta" },
    { id: "buttons" },
  ]
  const rows = [
    {
      fechaProgr: "fechaProgr",
      codigo: "codigo",
      lote: "lote",
      cliente: "cliente",
      planta: "planta",
    },
    {
      fechaProgr: "fechaProgr",
      codigo: "codigo",
      lote: "lote",
      cliente: "cliente",
      planta: "planta",
    },
    {
      fechaProgr: "fechaProgr",
      codigo: "codigo",
      lote: "lote",
      cliente: "cliente",
      planta: "planta",
    },
    {
      fechaProgr: "fechaProgr",
      codigo: "codigo",
      lote: "lote",
      cliente: "cliente",
      planta: "planta",
    },
    {
      fechaProgr: "fechaProgr",
      codigo: "codigo",
      lote: "lote",
      cliente: "cliente",
      planta: "planta",
    },
  ]
  const handleClickButonTable =(value,valueRow)=>{
    console.log(value,valueRow);
  }
  return (
    <DashBoardRight>
      <BoxHeaderRight>
        <TitleRight>Órdenes de Trabajo Pendientes</TitleRight>
        <DescriptionRight>Aquí podrá consultar los últimos 10 Ordenes de Trabajo pendientes,
        para poder acceder a la consulta completa de informes, presione la opción “Informes de Muestras” que se encuentra en la cabecera
        </DescriptionRight>
      </BoxHeaderRight>
      <BoxTable>
        <Tables
          typeTable={"prop"}
          columns={columns}
          rows={rows}
          cRows={6}
          pagination={true}
          buttons={buttonsDashboardLaboratorio}
          onClickButtonTable={(value, valuesRow) => { handleClickButonTable(value, valuesRow) }}
        />
      </BoxTable>
    </DashBoardRight>
  )
}

export default RightDashboardLaboratorio