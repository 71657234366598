import React from 'react';
import Button from '@mui/material/Button';
import { Styled } from "./CustomButtonStyled.ts";

interface Props {
  label?: string
  className?: string
  classNameDiv?: string
}
const CustomButton: React.FC<Props> = ({ label, className, classNameDiv, ...rest }: Props) => {
  return (
    <Styled className={`styledButton ${classNameDiv}`} >
      <Button
        className={className ? className : "button"} {...rest}>
        {label}
      </Button>
    </Styled>
  )
}

export default CustomButton;

