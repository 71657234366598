import React, { useState, useEffect } from 'react'
import RenderImg from '../RenderImg/RenderImg.tsx';
import { AlertStyled, TypographyTitle, BoxActionStyled } from './ToastStyled';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Error from "../../assets/Icons/Toast/Error.svg";
import Success from "../../assets/Icons/Toast/Success.svg";

const Toast = (props) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  const getProps = () => {
    let type;
    const typeError = {
      background: "#D44B4B",
      title: props.title ? props.title : "nada",
      icon: Error,
      alertMsj: "Error"
    }
    const typeSucces = {
      background: "#50A449",
      title: props.title ? props.title : "nada",
      icon: Success,
      alertMsj: ""
    }
    if (props.type === "error") {
      type = typeError;
    } else {
      type = typeSucces;
    }
    return type;
  };
  useEffect(() => {
    const timer = setTimeout(handleClose, 8000);
    return () => clearTimeout(timer);
  }, []);

  return (
    open && (
      <AlertStyled
        icon={<RenderImg img={getProps().icon} style={{ padding: "5px" }} />}
        style={{ background: getProps().background , zIndex: 9999, position: 'fixed', top: '0px', right: '0px', width: '95%', left: '0px', margin: 'auto' }}
        action={
          <BoxActionStyled>
            <TypographyTitle> {getProps().alertMsj}</TypographyTitle>
            <IconButton
              aria-label="close"
              color="inherit"
              size="medium"
              onClick={() => { props.close ? props.close() : handleClose() }}
              disableRipple
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </BoxActionStyled >
        }
      >
        <TypographyTitle>{getProps().title}</TypographyTitle>
      </AlertStyled >
    )
  )
}

export default Toast