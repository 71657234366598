import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import styled from "styled-components";
import Toast from "../../../../components/Toast/Toast";
import Modal2 from "../../../../components/Modal/Modal";
import Input from "../../../../components/Input/Input";
import {
  deleteIntervecion,
  getIntervecionPaginated,
  getIntervecionSearch,
  getEquipos,
  getClientes,
  getPlanta,
  getEquiposByClientes,
  getIntervencionByEquipo,
  getIntervencionByCliente,
} from "../../../../api/Clientes.js";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const columns = [
  { id: "fecha", label: "Fecha" },
  { id: "tipo", label: "Tipo Intervención" },
  { id: "intervencion", label: "Intervención" },
  { id: "equipo", label: "Equipo" },
  { id: "planta", label: "Planta" },
  { id: "cliente", label: "Cliente" },
  { id: "buttons" },
];

const buttonsHeadTable = [
  {
    label: "Nueva intervención",
    classname: "buttonHeaderTable",
  },
];

const Plantas = () => {
  const Navigate = useNavigate();
  const { state } = useLocation();
  const row = state ? state.row : null;

  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [intervenciones, setIntervenciones] = useState([]);
  const [equipos, setEquipos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [intervencionId, setIntevencionId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [selectedOptionequi, setSelectedOptionequi] = useState(null);
  const [selectedOptioncli, setSelectedOptioncli] = useState(null);
  const [key, setKey] = useState(1);

  useEffect(() => {
    fetchEquipos();

    getClientes().then((res) => {
      setClientes(
        res.data.items.map((res) => {
          return { label: res.desc_cli, value: res.id };
        })
      );
    });

    getPlanta().then((res) => {
      setPlantas(
        res.data.items.map((res) => {
          return { label: res.desc_pla, value: res.id };
        })
      );
    });

    if (row) {
      setSearchTerm(row.denominacion);
      setSelectedOptionequi({ label: row.denominacion, value: row.id });
      setSelectedOptioncli({ label: row.cliente, value: row.clienteId });
    } else {
      fetchIntervencionesPaginated(paginaActual, orderBy, searchTerm);
    }

    fetchIntervencionesPaginated(
      paginaActual,
      orderBy,
      row && row.denominacion ? row.denominacion : searchTerm
    );
  }, []);

  useEffect(() => {
    if (selectedOptioncli) {
      getEquiposByClientes(selectedOptioncli.value).then((res) => {
        setEquipos(
          res.data.map((res) => {
            return { label: res.desc_equi, value: res.id };
          })
        );
      });

      setSelectedOptionequi([]);
    } else {
      fetchIntervencionesPaginated(paginaActual, orderBy, "");
      setSelectedOptionequi([]);
    }
  }, [selectedOptioncli]);

  useEffect(() => {
    if (selectedOptionequi && selectedOptionequi.length > 0) {
      fetchIntervencionByEquipo(selectedOptionequi.value);
    }
  }, [selectedOptionequi]);

  useEffect(() => {
    fetchIntervencionesPaginated(paginaActual, orderBy, searchTerm, "");
  }, [searchTerm, paginaActual, orderBy]);

  const fetchIntervencionesPaginated = async (
    pagina,
    order,
    search,
    action
  ) => {
    const res =
      search === ""
        ? await getIntervecionPaginated(pagina, order, search)
        : await getIntervecionSearch(search);

    if (action !== "") {
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
    }
    if (action === "new" && intervenciones.length + 1 === 21) {
      setPaginaActual(res.data.totalPages);
    }

    if (action === "delete") {
      setKey(key + 1);
      setMsjToast("Intervencion eliminada con exito.");
      setSuccess(true);
    }
    setIntervenciones(
      res.data.items.map((res) => {
        return {
          id: res.id,
          observaciones: res.observacion_inte,
          fecha: res.fecha_inte,
          tipo: res.tipo.description,
          intervencion: res.desc_inte,
          equipo: res.equipo.description,
          planta: res.planta.description,
          cliente: res.cliente.description,
          clienteId: res.cliente.id,
          equipoId: res.equipo.id,
          plantaId: res.planta.id,
          tipoId: res.tipo.id,
        };
      })
    );
  };

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setIntevencionId(valueRow.id);
      setModalStatus2(true);
    }
  };
  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };
  const fetchEquipos = async () => {
    getEquipos().then((res) => {
      setEquipos(
        res.data.items.map((res) => {
          return { label: res.desc_equi, value: res.id };
        })
      );
    });
  };
  const fetchIntervencionByCliente = async (id) => {
    getIntervencionByCliente(id).then((res) => {
      setSelectedOptionequi("");
      setIntervenciones(
        res.data.items.map((res) => {
          return {
            id: res.id,
            observaciones: res.observacion_inte,
            fecha: res.fecha_inte,
            tipo: res.tipo.description,
            intervencion: res.desc_inte,
            equipo: res.equipo.description,
            planta: res.planta.description,
            cliente: res.cliente.description,
            clienteId: res.cliente.id,
            equipoId: res.equipo.id,
            plantaId: res.planta.id,
            tipoId: res.tipo.id,
          };
        })
      );
    });
  };

  const fetchIntervencionByEquipo = async (id) => {
    getIntervencionByEquipo(id).then((res) => {
      setIntervenciones(
        res.data.items.map((res) => {
          return {
            id: res.id,
            observaciones: res.observacion_inte,
            fecha: res.fecha_inte,
            tipo: res.tipo.description,
            intervencion: res.desc_inte,
            equipo: res.equipo.description,
            planta: res.planta.description,
            cliente: res.cliente.description,
            clienteId: res.cliente.id,
            equipoId: res.equipo.id,
            plantaId: res.planta.id,
            tipoId: res.tipo.id,
          };
        })
      );
    });
  };

  const handleDelete = (id) => {
    deleteIntervecion(id)
      .then(() => {
        setIntevencionId("");
        let paginaActualTemp = paginaActual;
        if (intervenciones.length - 1 === 0 && paginaActual > 1) {
          paginaActualTemp = paginaActual - 1;
          setPaginaActual(paginaActualTemp);
        }
        return fetchIntervencionesPaginated(
          paginaActualTemp,
          orderBy,
          searchTerm,
          "delete"
        );
      })
      .catch((err) => {
        setKey(key + 1);
        setMsjToast("Error al eliminar la intervencion.");
        setError(true);
      });
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  return (
    <Styled key={key}>
      <div className="toast">
        {success ? (
          <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            key={key}
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        ) : null}
      </div>

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          setKey(key + 1);
          setMsjToast(msj);
          fetchIntervencionesPaginated(
            paginaActual,
            orderBy,
            searchTerm,
            "new"
          );
          if (status === "success") {
            setSuccess(true);
          } else if (status === "error") {
            setError(true);
          }
          setModalStatus(false);
        }}
        type={"intervenciones"}
      />

      <div className="compexterno">
        <HeaderTable
          styledHead="clientes"
          clientes
          title="Intervenciones"
          datosButtons={buttonsHeadTable}
          // valueBuscador={(value) => console.log(value)}
          valueBuscador={(value) => setSearchTerm(value)}
          onClickButton={() => {
            handleClickButtonHeader();
          }}
          multiInputs
          selectInput={(e) => {
            setSearchTerm(e.target.value.label);
          }}
          buttonSet={() => {
            setSearchTerm("");
          }}
          // buttonSet={()=>{}}
          clean
        />
        <div className="compinterno">
          <Input
            isClearable
            reactSelect
            stylesHeader
            placeholder={"Cliente"}
            options={clientes}
            onChange={(e) => {
              if (e === null) {
                setSelectedOptioncli("");
                fetchEquipos();
                setSearchTerm(""); //alert(JSON.stringify(e))
              } else {
                setSelectedOptioncli(e);
                fetchIntervencionByCliente(e.value);
              }
            }}
            value={selectedOptioncli}
          />
          <Input
            isClearable
            reactSelect
            stylesHeader
            placeholder={"Equipo"}
            options={equipos}
            onChange={(e) => {
              if (e === null) {
                if (selectedOptioncli !== null && selectedOptioncli !== "") {
                  fetchIntervencionByCliente(selectedOptioncli.value);
                } else {
                  setSelectedOptionequi("");
                  setSearchTerm(""); //alert(JSON.stringify(e))
                }
              } else {
                setSelectedOptionequi(e);
                setSearchTerm(e.label); //alert(JSON.stringify(e))
              }
            }}
            value={selectedOptionequi}
          />
        </div>
      </div>

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={intervenciones}
        cRows={20}
        totalRows={itemsCount}
        pagination={true}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
        // onClickSwitch={(id)=> alert(id)}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDelete(intervencionId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default Plantas;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
    //height: 100vh;
  }

  & .compexterno {
    position: relative;
  }
  & .compinterno {
    width: 400px;
    position: absolute;
    left: 29%;
    top: 43%;
    display: flex;
    flex-direction: row;

    gap: 10px;

    & .divInput {
      width: 100%;
    }
  }
`;
