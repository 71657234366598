import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import styled from "styled-components";
import {
  getTipoLubricantes,
  createTipoLubricante,
  deleteTipoLubricante,
  getTipoLubricantesSearch,
  getTiposLubricantesPaginated,
} from "../../../../api/Configuracion";

import Modal2 from "../../../../components/Modal/Modal";
import Toast from "../../../../components/Toast/Toast";
const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
];
const rows = [
  {
    codigo: "Código",
    descripcion: "Descripción",
    habilitado: "Habilitado",
  },
];
const TipoUnidades = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [tipoLubricantes, setTipoLubricantes] = useState([]);
  const [tipoLubricanteId, setTipoLubricantesId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
    const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [key, setKey] = useState(1);
  const [rows, setRows] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);

  useEffect(() => {
    getTiposLubricantesPaginated(paginaActual, orderBy, searchTerm).then(
      (res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);        
        // alert(JSON.stringify(res.data))
        setTipoLubricantes(
          res.data.items.map((res) => {
            return {
              id: res.id,
              codigo: res.codigo_tplub,
              descripcion: res.desc_tplub,
              habilitado: res.status,
            };
          })
        );
      }
    );
  }, []);

  useEffect(() => {
    getTiposLubricantesPaginated(paginaActual, orderBy, searchTerm).then(
      (res) => {
        // alert(JSON.stringify(res.data))
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setTipoLubricantes(
          res.data.items.map((res) => {
            return {
              id: res.id,
              codigo: res.codigo_tplub,
              descripcion: res.desc_tplub,
              habilitado: res.status,
            };
          })
        );
      }
    );
  }, [orderBy, paginaActual]);

  useEffect(() => {
    if(searchTerm === ""){
    getTiposLubricantesPaginated(paginaActual, orderBy, searchTerm).then(
      (res) => {
        // alert(JSON.stringify(res.data))
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setTipoLubricantes(
          res.data.items.map((res) => {
            return {
              id: res.id,
              codigo: res.codigo_tplub,
              descripcion: res.desc_tplub,
              habilitado: res.status,
            };
          })
        );
      }
    ).catch((err) =>
      console.error("Error al obtener datos de tipo lubricantes:", err)
    );
  }else{
    setPaginaActual(1);
    getTipoLubricantesSearch("",  searchTerm).then(
      (res) => {
        // alert(JSON.stringify(res.data))
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setTipoLubricantes(
          res.data.items.map((res) => {
            return {
              id: res.id,
              codigo: res.codigo_tplub,
              descripcion: res.desc_tplub,
              habilitado: res.status,
            };
          })
        );
      }
    ).catch((err) =>
      console.error("Error al obtener datos de lubricantes:", err)
    );;
  }
  }, [searchTerm]);


  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setTipoLubricantesId(valueRow.id);
      setModalStatus2(true);
     // handleDeleteTipoLubricante(valueRow.id);
    }
  };
  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };
  const handleDeleteTipoLubricante = (unidadId) => {
    deleteTipoLubricante(unidadId)
      .then(() => {
        setTipoLubricantesId("");
        setKey(key+1);
        setMsjToast("Tipo de de Lubricante eliminado con exito");
        let paginaActualTemp=paginaActual;
        if((tipoLubricantes.length -1)===0 && paginaActual>1  ){         
         paginaActualTemp = (paginaActual-1);
         setPaginaActual(paginaActualTemp);                 
        }   
        return getTiposLubricantesPaginated(paginaActualTemp, orderBy, searchTerm)
      })
      .then((res) => {
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
        setSuccess(true);
        setMsjToast("Tipo de Lubricante eliminado con exito");
        setTimeout(() => {
          setSuccess(false);
          setTipoLubricantes(
            res.data.items.map((res) => {
              return {
                id: res.id,
                codigo: res.codigo_tplub,
                descripcion: res.desc_tplub,
                habilitado: res.status,
              };
            })
          );
        }, 1500);
      })
      .catch((err) => { 
        setKey(key+1);
        setMsjToast("Error al eliminar El tipo de Lubricante")
        setError(true)
      });
  };

  
  const handleOrder = (value) => {
    let valor;
    if(orderBy==="" || orderBy.replace("-","").length!==value.length ){
      valor=value;
    }else{
     
      valor=orderBy
    }
  
    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;       
      setOrderBy(updatedValue);
    }
  };

  return (
    <Styled key={key}>
      <div
        className="toast"
        style={{ right: "0px", zIndex: "1000", left: "0px" }}
      >
        {success && <Toast key={key} title={msjToast} close={() => setSuccess(false)} /> }

        {error && <Toast
            key={key}
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />}
      </div>
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={({msj,status}) => {         
          setSuccess(false);
          setError(false);
          if(status === "success"){           
            setKey(key+1)
            setSuccess(true);
            setMsjToast(msj);
          }else if(status === "error"){
            setKey(key+1);
            setError(true);
            setMsjToast(msj);   
          }
          setModalStatus(false);          

          getTiposLubricantesPaginated(paginaActual, orderBy, searchTerm).then((res) => {
            setPageCount(res.data.totalPages);
            setItemsCount(res.data.totalItems);
            setTipoLubricantes(
              res.data.items.map((res) => {
                return {
                  id: res.id,
                  codigo: res.codigo_tplub,
                  descripcion: res.desc_tplub,
                  habilitado: res.status,
                };
              })
            );
          });
        }}
        type={"tipoLubricantes"}
        // iconX={valueTicket === "Ver"}
      />
      <HeaderTable
        styledHead="cfg"
        cfg
        title="Tipo de Lubricantes"
        valueBuscador={(value) => setSearchTerm(value)}       
        labelButton={"Nuevo tipo de lubricante"}
        onClickButton={() => {
          handleClickButtonHeader();
        }}
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={tipoLubricantes}
        cRows={20}
        totalRows={itemsCount}
        pagination={true}
        page={(e)=>setPaginaActual(e+1)}
        pages={pageCount}
        // valueBuscador={valueBuscador}
        onClickSwitch={(value) => {handleOrder(value) }}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      />
       <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteTipoLubricante(tipoLubricanteId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default TipoUnidades;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
   // height: 100vh;
  }
`;
