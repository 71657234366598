import React, { useReducer, useState } from 'react';
import Input from '../../../../Input/Input.jsx';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Tables from '../../../../Tables/Tables.jsx';
import CustomButton from '../../../../Button/CustomButton.tsx';
import Modal from '../../../Modal.jsx';
import dayjs from 'dayjs';

import MenuItem from '@mui/material/MenuItem';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import ArticleIcon from '@mui/icons-material/Article';
import BiotechIcon from '@mui/icons-material/Biotech';

import { StyledModalcomponentMuestras } from '../../../Styled.jsx';

const columnsDefaultCfg = [
  {
    id: "button", label: <CustomButton
      onClick={() => { alert("refrescar") }}
      startIcon={<RefreshIcon />}
      label={"Refrescar"}
      variant="contained"
      size="small"
      className={`buttonColorDisabled`}
    />
  },
  { id: "cantidadmuestras", label: "Cantidad Muestras" },
  { id: "resultados", label: "Resultados (Cargados/Totales)" },
]
const columnsDefaultCfgDos = [
  { id: "fecha", label: "Fecha " },
  { id: "usuario", label: "usuario" },
  { id: "accion", label: "Acción" },
  { id: "estado", label: "estado" },
]

const datosRows = [
  {
    button: "Sin diagnóstico",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Sin sugerencias",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Sin estado final",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Sin fecha de análisis",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Sin  imágenes",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Sin resultados",
    cantidadmuestras: "0",
    resultados: "0",
  },
  {
    button: "Listas para enviar",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Enviadas",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Total de muestras",
    cantidadmuestras: "0",
    resultados: "",
  }
]
const datosRowsDos = [
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar"
  },
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar"
  },
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar"
  },
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar"
  }, {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar"
  },
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar"
  }, {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar"
  },
]

const OrdenesDeTrabajoModal = (props) => {
  const [statusModal, setModalStatus] = useState(false);
  const [labelModalNoti, setLabelModalNoti] = useState("");
  const propsOrdenesdeTrabajo = (datosUnidades) => {
    const controlPanel = datosUnidades?.button === "controlpanel" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { controlPanel, editar, datos }
  }
  const { controlPanel, editar, datos } = propsOrdenesdeTrabajo(props.datos);
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    cliente: datos.cliente ?? '',
    planta: datos.planta ?? '',
    fecha: datos.fecha ? dayjs(datos.fecha) : null,
    codigo: datos.codigo ?? '',
    lote: datos.lote ?? '',
    descripcion: datos.descripcion ?? '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const fieldValue = value;
    setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
  };
  const handleChangeCalendar = (e, nameCalendar) => {
    const name = nameCalendar;
    const value = e.$d;
    setForm({ type: 'SET_FIELD', field: name, value: value });
  };

  const titulo = () => {
    const titulo = controlPanel ? "Control Panel Ordenes de Trabajo" : editar ? "Editar Ordenes de Trabajo" : "Crear o editar Ordenes de Trabajo";
    return titulo
  }
  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={"Cancelar"}
          variant="contained"
          className={`buttonColorDisabled btn`}
        />

        <CustomButton
          label={"Confirmar"}
          variant="contained"
          className={`buttonColorDefault btn`}
          type="submit"
          onClick={() => { alert("confirmar") }}
        // disabled={!editar ? !formCompleto : false}
        />

      </>
    );
  };
  const handleModalNotificar = () => {
    return (
      <Modal
        isOpen={statusModal}
        type={"notiordenesdetrabajo"}
        onClose={() => setModalStatus(false)}
        modalNav={true}
        titulo={labelModalNoti}
      />
    );
  }

  return (
    <StyledModalcomponentMuestras sx={controlPanel ? {
      width: "70%", '@media (max-width: 768px)': {
        width: "90%",
        overflowY: "scroll",
        height: "70%",
      },
      '@media (max-width: 1500px)': {
        width: "90%",
        height: "80%",
        overflowY: "scroll",
      }
    } : {
      width: "60%", '@media (max-width: 768px)': {
        width: "90%",
        overflowY: "scroll",
        height: "70%",
      },
      '@media (max-width: 1400px)': {
        width: "90%",
        height: "80%",
        overflowY: "scroll",
      }
    }}>
      {handleModalNotificar()}

      {!controlPanel ?
        <div className="ordenesDeTrabajo">
          <div className="divLabel">
            <p className="titulo"> {titulo()} </p>
          </div>
          <div className="divInputsItem">
            <form className="formulario" >
              <div className="divInputsUno">
                <Input
                  value={form.cliente}
                  label="Seleccione el cliente"
                  classnamediv="divInputCliente divInput"
                  className="input inputCliente"
                  variant="outlined"
                  size={"medium"}
                  name="cliente"
                  onChange={handleChange}
                  select
                >
                  <MenuItem value={datos.planta ?? "Option 1"}>{datos.planta ?? "Option 1"}</MenuItem>
                  <MenuItem value="option2">Option 2</MenuItem>
                  <MenuItem value="option3">Option 3</MenuItem>
                </Input>

                <Input
                  value={form.planta}
                  label="Seleccione la planta"
                  placeholder="Seleccione la planta"
                  select
                  classnamediv="divInputPlanta divInput"
                  className="input inputPlanta"
                  variant="outlined"
                  size={"medium"}
                  name="planta"
                  onChange={handleChange}

                >
                  <MenuItem value={datos.planta ?? "Option 1"}>{datos.planta ?? "Option 1"}</MenuItem>
                  <MenuItem value="option2">Option 2</MenuItem>
                  <MenuItem value="option3">Option 3</MenuItem>
                </Input>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                    <DatePicker
                      className="inputDate"
                      label="Fecha programada"
                      // value={form.fecha}
                      slotProps={<Input
                        className="input inputDate"
                        variant="outlined"
                        size={"medium"}
                      />}
                      onChange={(e) => { handleChangeCalendar(e, "fecha") }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className="divInputsDos">
                <Input
                  value={form.codigo}
                  label="Código"
                  classnamediv="divInputCodigo divInput"
                  className="input inputCodigo"
                  variant="outlined"
                  size={"medium"}
                  name="codigo"
                  onChange={handleChange}
                />
                <Input
                  value={form.lote}
                  label="Lote"
                  classnamediv="divInputLote divInput"
                  className="input inputLote"
                  variant="outlined"
                  size={"medium"}
                  name="lote"
                  onChange={handleChange}
                />
                <Input
                  value={form.descripcion}
                  label="Descripción"
                  classnamediv="divInputDescripcion divInput"
                  className="input inputDescripcion"
                  variant="outlined"
                  size={"medium"}
                  name="descripcion"
                  onChange={handleChange}
                />

              </div>
              <div className="divButtonsItem">
                {renderButtons()}
              </div>

            </form>
          </div>
        </div>
        :
        <div className="ordenesDeTrabajoPanelControl">
          <div className="divLabel">
            <p className="titulo"> Control Panel Ordenes de Trabajo </p>
          </div>
          <form className='formularioPanelControl'>

            <div className="divColumnaUno">
              <div className="divHeader">
                <CustomButton
                  onClick={() => { alert("EDITAR OT") }}
                  startIcon={<CreateOutlinedIcon />}
                  label={"Editar OT"}
                  variant="contained"
                  className={`buttonColorTable`}
                />

              </div>
              <div className="divInputsPanelControl">
                <div className="divUnoPanelControl">
                  <Input
                    value={form.codigo}
                    label="Código"
                    classnamediv="divInputCodigo"
                    className="input inputCodigo"
                    variant="outlined"
                    size={"small"}
                    name="codigo"
                    onChange={handleChange}
                  />
                  <Input
                    value={form.lote}
                    label="Lote"
                    classnamediv="divInputLote"
                    className="input inputLote"
                    variant="outlined"
                    size={"small"}
                    name="lote"
                    onChange={handleChange}
                  />
                  <Input
                    value={form.descripcion}
                    label="Descripción"
                    classnamediv="divInputDescripcion"
                    className="input inputDescripcion"
                    variant="outlined"
                    size={"small"}
                    name="descripcion"
                    onChange={handleChange}
                  />
                </div>
                <div className="divDosPanelControl">
                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                      <DatePicker
                        className="inputDate"
                        label="Fecha programada"
                        placeholder="Fecha programada"
                        value={form.fecha}
                        onChange={(e) => { handleChangeCalendar(e, "fecha") }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <Input
                    value={form.cliente}
                    label="Cliente"
                    classnamediv="divInputCliente"
                    className="input inputCliente"
                    variant="outlined"
                    size={"small"}
                    name="cliente"
                    onChange={handleChange}
                  />
                  <Input
                    value={form.planta}
                    label="Planta"
                    classnamediv="divInputPlanta"
                    className="input inputPlanta"
                    variant="outlined"
                    size={"small"}
                    name="Planta"
                    onChange={handleChange}
                  />

                </div>
                <div className="divTresPanelControl">
                  <Input
                    value={form.estado}
                    label="Estado Actual"
                    classnamediv="divInputEstado"
                    className="input inputEstado"
                    variant="outlined"
                    size={"small"}
                    name="estado"
                    onChange={handleChange}
                  />
                  <CustomButton
                    startIcon={<ForwardToInboxIcon />}
                    label={"Notificar"}
                    variant="contained"
                    size={"small"}
                    className={`buttonColorDefault`}
                    onClick={() => {
                      handleModalNotificar();
                      setModalStatus(true);
                      setLabelModalNoti("notificar");
                    }}
                  />
                </div>
                <div className="divCuatroPanelControl">
                  <p className="title">Cambios de Estado</p>
                  <Tables columns={columnsDefaultCfgDos} rows={datosRowsDos} typeTable={'prop'} pagination={true} tableColor={false} />
                </div>
              </div>
            </div>
            <div className="divColumnaDos">
              <div className="divHeaderColumnaDos">
                <div className="buttonUno">
                  <CustomButton
                    onClick={() => { alert("crear nueva muestra") }}
                    startIcon={<AddIcon />}
                    label={"Crear nueva muestra"}
                    variant="contained"
                    size="small"
                    className={`buttonHeaderTable`}
                  />
                </div>
                <div className="buttonsDos">
                  <CustomButton
                    onClick={() => { alert("diag.masivo") }}
                    startIcon={<BiotechIcon />}
                    label={"Diag. Masivo"}
                    variant="contained"
                    size="small"
                    className={`buttonColorTable`}
                  />
                  <CustomButton
                    onClick={() => { alert("excel") }}
                    startIcon={<ArticleIcon />}
                    label={"Excel resultados"}
                    variant="contained"
                    size="small"
                    className={`buttonColorTable`}
                  />
                </div>
              </div>
              <Tables columns={columnsDefaultCfg} rows={datosRows} typeTable={'prop'} pagination={false} tableColor={true} />
            </div>

          </form>
          <div className="divButtonsItem">
            {renderButtons()}
          </div>
        </div>}
    </StyledModalcomponentMuestras>
  )
}

export default OrdenesDeTrabajoModal