import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import { colors, fonts } from "../../assets/styles/theme.ts";

export const Styled = styled(Box)`
  width: 100%;
  height: 60px;
  background-color: ${colors.lightGray};
  @media screen and (max-width: 768px) {
    height: auto;
    padding: 10px 0px;
  }
`;
export const DivBody = styled(Box)`
  display: flex;
  padding: 0px 20px;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export const DivImgFooter = styled(Box)`
  width: 50px;
  overflow: hidden;
  padding: 10px 0px;
  .img {
    max-width: 100%;
    height: auto;
  }
`;
export const LabelFooter = styled(Typography)`
  && {
    color: ${colors.blueDark};
    font-family: ${fonts.primary.fontFamily};
    font-weight: ${fonts.primary.fontWeight};
    flex-direction: column;
    font-size: 12px;
    display: flex;
    width: 100%;
    .labelLink {
      width: 85%;
      justify-content: space-between;
      display: flex;
      font-weight: 400;
    }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    .labelLink {
      text-align: center;
      flex-direction: column;
      width: 100%;
    }
  }
  }
`;
