import React, { useReducer } from 'react'
import { StyledModalcomponentClientesExternos } from '../../Styled.jsx';
import { Box } from '@mui/material';
import Input from '../../../Input/Input.jsx';
import CustomButton from '../../../Button/CustomButton.tsx';
import Tables from '../../../Tables/Tables.jsx';

const columnsDefaultCfg = [
  { id: "fecha", label: "Fecha" },
  { id: "tipoEvento", label: "Tipo Intervención" },
]

const datosRows = [
  {
    fecha: "fecha",
    tipoEvento: "tipoEvento",
  },
  {
    fecha: "fecha",
    tipoEvento: "tipoEvento",
  },
  {
    fecha: "fecha",
    tipoEvento: "tipoEvento",
  },

]


const IntervencionesClientesExternosModal = (props) => {
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    fecha: props.datos.fecha ?? '',
    tipoIntervencion: props.datos.tipoIntervencion ?? '',
    intervencion: props.datos.intervencion ?? '',
    observaciones: props.datos.observaciones ?? '',
    equipo: props.datos.equipo ?? '',
    planta: props.datos.planta ?? '',
  });
  const propsIntervencionesClientesExternos = (datosIntervencionesClientesExternos) => {
    console.log(datosIntervencionesClientesExternos?.datos)
    const ver = datosIntervencionesClientesExternos?.button === "ver" ?? false;
    const editar = datosIntervencionesClientesExternos?.button === "editar" ?? false;
    const datos = datosIntervencionesClientesExternos?.datos ?? [];
    return { ver, editar, datos }
  }
  const { ver, editar, datos } = propsIntervencionesClientesExternos(props.datos);
  console.log(props.datos);
  const titulo = () => {
    const titulo = ver ? "Ver Intervenciones" : editar ? "Editar Intervenciones" : "Crear o editar Intervenciones";
    return titulo
  }
  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
          //  disabled={!editar ? !formCompleto : false}
          />
        )}
      </>
    );
  };
  return (
    <StyledModalcomponentClientesExternos sx={{
      width: "60%",
      padding: "40px",
      '@media (max-width: 768px)': {
        width: "90%",
      },
    }}>
      <div className="divLabel" >
        <p className="titulo">{titulo()}</p>
      </div>
      <div className="intervencionesClientesExternos">
        <div className="boxInputs">
          <Box sx={{
            display: "flex", gap: "10px", alignItems: "center", '@media (max-width: 768px)': {
              flexDirection: "column"
            }
          }} >
            <Input
              inputProp={true}
              placeholder={"Cliente"}
              onChange={(e) => {
                console.log(e);
              }}
              value={form.cliente}
              name={"cliente"}
              className="inputDefault"
            />
            <Input
              reactSelect
              placeholder="Planta"
            />
          </Box>
          <Box sx={{
            display: "flex", gap: "10px", alignItems: "center", '@media (max-width: 768px)': {
              flexDirection: "column"
            }
          }} >
            <Input
              reactSelect
              placeholder="Equipo"
            />
            <Input
              reactSelect
              placeholder="Tipo de Evento"
            />
          </Box>
          <Box sx={{
            display: "flex", gap: "10px", alignItems: "center", '@media (max-width: 768px)': {
              flexDirection: "column"
            }
          }} >
            <Input
              reactSelect
              placeholder="Equipo"
            />
            <Input
              reactSelect
              placeholder="Tipo de Evento"
            />
          </Box>
          <Box>
            <Input
              inputProp={true}
              placeholder={"Observaciones"}
              onChange={(e) => {
                console.log(e);
              }}
              name={"observaciones"}
              className="inputDefault"
            />
          </Box>
        </div>
        <div className="boxTable">
          <p className="titulo">Eventos Registrados</p>
          <Tables
            columns={columnsDefaultCfg}
            rows={datosRows}
            typeTable={'prop'}
            pagination={true}
            tableColor={false}
          />
        </div>
      </div>
      <div className="divButtonsItem">
        {renderButtons()}
      </div>
    </StyledModalcomponentClientesExternos >
  )
}

export default IntervencionesClientesExternosModal