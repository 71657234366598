import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import { colors, fonts } from "../../assets/styles/theme.ts";

export const Styled = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 10px 20px;
  background-color: ${colors.lightGray};
  border-bottom: 10px solid ${colors.turquoise};
  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    gap: 10px;
  }
`
export const DivImg = styled(Box)`
  display: flex;
  align-items: center;
  .img{
    max-width: 100px;
  }
  @media screen and (max-width: 768px) {
    margin: auto;
  }
`
export const DivButtonsNav = styled(Box)`
  display:flex;
  align-items: center;
  gap: 35px;
  button{font-size: 18px;}
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    order: 2;
  }
`;
export const Title = styled(Typography)`
  &&{
    ${fonts.primary}
    ${colors.blueDark}
    font-weight: 500;
    font-size: 20px;
    line-height: 29.05px;
    
    @media screen and (max-width: 768px) {
    font-size: 15px ;
    text-align: center;
    ${fonts.primary}
    ${colors.blueDark}
    }
  }
`
