import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { colors, fonts } from "../../assets/styles/theme.ts";

export { default as RenderImg } from "../RenderImg/RenderImg.tsx";
export { default as LimsOil } from "../../assets/Login/LimsOil.png";
export { default as ImgIndex } from "../../assets/ImgIndex.png";
export { default as TextField } from "@mui/material/TextField";
export { default as CustomButton } from "../Button/CustomButton.tsx";

export const Styled = styled(Box)`
  display: flex;
  height: 100vh;
`;
//Izquierda
export const DivLogin = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 20%;
  padding-top: 5%;
  padding-right: 5%;
  padding-left: 5%;
  gap: 50px;
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;
export const DivImagen = styled(Box)`
  text-align: center;
  padding-bottom: 10%;
`;
export const Titulo = styled(Typography)`
  &&{
  font-family: ${fonts.primary};
  color: ${colors.blueDark};
  font-size: 32px;
  text-align: center;
  font-weight: 600;
  line-height: 38.73px;
  }
`;
export const Formulario = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 40px 0px;
  .input {
    background: ${colors.lightGray};
    border: 1px solid ${colors.lightGray};
    border-radius: 5px;
    fieldset{
      border: none;
    }
  }
  .divButton {
    padding: 50px 0px;
  }
  .buttonColorDefault {
    padding: 15px 0px;
  }
`;
//
export const DivHr = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  .hr {
    border-left: 1px solid ${colors.turquoise};
    height: 85%;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
//Derecha
export const DivBackground = styled(Box)`
  display: flex;
  width: auto;
  justify-content: center;
  width: 80%;
  .img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
