import React, { useReducer, useState, useEffect } from 'react';
import Input from '../../../../Input/Input.jsx';
import DropZone from '../../../../DropZone/DropZone.jsx';
import CustomButton from '../../../../Button/CustomButton.tsx';
import MenuItem from '@mui/material/MenuItem';
import Checkboxx from '../../../../Checkbox/Checkboxx.jsx';
import { useNavigate } from 'react-router-dom';
import { StyledModalcomponentMuestras } from '../../../Styled.jsx';

const ImportacionImagenesModal = (props) => {
  // const propsImportacionResultados = (datosUnidades) => {

  //   return {}
  // }
  // const {} = propsImportacionResultados(props.datos);
  const navigate = useNavigate();
  const [formCompleto, setFormCompleto] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    cliente: '',
    ordenTrabajo: '',
  });

  useEffect(() => {
    const campoValido = campo => form[campo] !== '';
    const camposaValidar = ['cliente', 'ordenTrabajo'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const fieldValue = value;
    setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  }
  const titulo = () => {
    const titulo = "Importación de  Imagenes"
    return titulo
  }
  const handleClickCancelar = () => {
    if (props.onClick) {
      props.onClick();
    }
    navigate(-1);
  };

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={handleClickCancelar}
          label={"Cancelar"}
          variant="contained"
          className={`buttonColorDisabled`}
        />

        <CustomButton
          label={"Confirmar"}
          variant="contained"
          className={`buttonColorDefault btn`}
          type="submit"
          disabled={!formCompleto && false}
        />

      </>
    );
  };
  const onFileChange = (file) => {
    console.log(file);
    setSelectedFile(file);
  };
  
  return (
    <StyledModalcomponentMuestras sx={{
      width: "60%",
      padding: "40px",
      '@media (max-width: 768px)': {
        width: "90%",
      },
    }}>
      <div className="importaciones">
        <div className="divLabel">
          <p className="titulo">{titulo()}</p>
        </div>
        <form className="formulario" onSubmit={handleSubmit}>
          <div className="divInputs">
            <div className="divUnoInput">
              <Input
                value={form.cliente}
                label="Cliente"
                classnamediv="divInputImportaciones divInput"
                className="input inputImportaciones"
                variant="outlined"
                size="medium"
                name="cliente"
                onChange={handleChange}
                select
              >
                <MenuItem value="option2">Option 2</MenuItem>
                <MenuItem value="option3">Option 3</MenuItem>
              </Input>
              <Input
                value={form.ordenTrabajo}
                label="Orden de Trabajo"
                classnamediv="divInputImportaciones divInput"
                className="input inputImportaciones"
                variant="outlined"
                size="medium"
                name="ordenTrabajo"
                onChange={handleChange}
                select
              >
                <MenuItem value="option2">Option 2</MenuItem>
                <MenuItem value="option3">Option 3</MenuItem>
              </Input>
            </div>
            <div className="divDosInput">
              <Checkboxx label="¿Reemplazar resultados?" name="remplazar" onChange={(e) => { console.log(e) }} value="pepe" />
              <DropZone onFileSelected={onFileChange} />
            </div>
            <div className="divButtonsItem">
              {renderButtons()}
            </div>
          </div>
        </form>
      </div>
    </StyledModalcomponentMuestras>
  )
}

export default ImportacionImagenesModal