import React from 'react';
import CustomButton from '../../../../Button/CustomButton.tsx';

import { StyledModalcomponentMuestras } from '../../../Styled';

const NotiOrdenesDeTrabajo = (props) => {

  const propsNoti = (datos) => {
    const titulo = datos?.titulo;

    return { titulo }
  }

  const { titulo } = propsNoti(props.datos);
  console.log(props.datos);
  const renderLabel = () => {
    let tituloLabel = null;
    let descripcionLabel = null;
    if (titulo === "notificar") {
      tituloLabel = "NOTIFICACIÓN de OT"
      descripcionLabel = "Al Notificar la OT, se enviará un mail al cliente, indicando que se recibieron las muestras y se inició el proceso de análisis de las mismas."
    }
    if (titulo === "finalizar") {
      tituloLabel = "FINALIZACIÓN de OT"
      descripcionLabel = "Al Finalizarr la OT, se enviará un mail al cliente, indicando que se realizaron todos los análisis correspondientes a todas las muestras de la OT."
    }
    return { tituloLabel, descripcionLabel };
  }

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          label={"Si"}
          variant="contained"
          className={`buttonColorDefault btn`}
          type="submit"
        // disabled={!editar ? !formCompleto : false}
        />
        <CustomButton
          onClick={props.onClick}
          label={"No"}
          variant="contained"
          className={`buttonColorDisabled btn`}
        />
      </>
    );
  };

  return (
    <StyledModalcomponentMuestras>
      <div className="notiOrdenesDeTrabajo">
        <div className="divLabel">
          <p className="titulo">{renderLabel().tituloLabel}</p>
        </div>
        <div className="divBody">
          <div className="divDescripcion">
            <p className="descripcion">{renderLabel().descripcionLabel}</p>
          </div>
          <p className="confirmacion">Confirma el cambio de estado?</p>
          <div className="divButtonsItem">
            {renderButtons()}
          </div>
        </div>
      </div>
    </StyledModalcomponentMuestras>
  )
}

export default NotiOrdenesDeTrabajo