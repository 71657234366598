import React, { useState, useEffect } from 'react'
import bcrypt from 'bcryptjs';
import { lookupUser, updateUser } from '../../api/auth.js';
import { useAuth } from '../../context/AuthContext.jsx';
import { useForm } from 'react-hook-form';
import { Box, Divider } from '@mui/material';
import { colors } from "../../assets/styles/theme.ts";
import { Styled, BoxMiCuenta, Title, Description, Header, BoxImg, Body, BoxDataUsuario } from './MiCuentaStyled';
import { EditOutlined, AccountCircleOutlined, AlternateEmailOutlined, LockResetOutlined } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import CustomButton from '../../components/Button/CustomButton.tsx';
import RenderImg from '../../components/RenderImg/RenderImg.tsx';
import LimsOil from "../../assets/Login/LimsOil.png";

const MiCuenta = () => {
  const { token } = useAuth();
  const [editState, setEditState] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [user, setUser] = useState({});
  const tokenId = token ? token.id : "";
  const { register, handleSubmit, setValue } = useForm();

  const toggleEdit = (index) => {
    setEditState((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setPreview(URL.createObjectURL(file));
      setValue("img", file);
    }
  };
  console.log(token?.login);
  const onSubmit = async (value) => {
    let json = {
      firstName: value?.nombre ?? user?.firstName,
      lastName: value?.lastName ?? user?.lastName,
      email: value?.email ?? user?.email,
      passwordHash: value?.password ?? "123456", 
      id: tokenId,
      login: token?.login,
      createdDate: new Date().toISOString(),
      activated: true,
      rol: user?.rol,
      tenantId: "1",
      createdBy: "admin",
      status: "ACTIVE",
      mostrar: "SI",
    };
  
    try {
      const response = await updateUser(json);
      console.log('Rdy:', response);
    } catch (error) {
      console.error('todo mal', error);
    }
  };
  useEffect(() => {
    let isMounted = true;
    const fetchUser = async () => {
      try {
        const res = await lookupUser(tokenId);
        if (isMounted) {
          setUser(res?.data ?? {});
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
      }
    };
    fetchUser();
    return () => {
      isMounted = false;
    };
  }, [tokenId]);
  const renderData = () => {
    const data = [
      {
        title: "Nombre y Apellido",
        icon: <AccountCircleOutlined fontSize='medium' />,
        name: "nombre",
        type: "text",
        placeholder: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`
      },
      {
        title: "Email",
        icon: <AlternateEmailOutlined fontSize='medium' />,
        name: "email",
        type: "email",
        placeholder: user?.email && user.email
      },
      {
        title: "Contraseña",
        icon: <LockResetOutlined fontSize='medium' />,
        name: "password",
        type: "password",
        placeholder: "**********"
      }
    ]
    return data.map((res, i) => {
      return <BoxDataUsuario
        key={i}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          padding: "20px"
        }}>
        <BoxDataUsuario>
          <Title sx={{ fontSize: "14px", fontWeight: "700", display: "flex", gap: "10px" }}>
            <span>
              {res.icon}
            </span>
            {res.title}
          </Title>
          {editState[i] ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                type={res.type}
                variant="standard"
                {...register(res.name)}
                className="input"
                size="small"
                placeholder={res.placeholder}
              />
              <IconButton style={{ cursor: "pointer" }} onClick={() => toggleEdit(i)}>
                <EditOutlined sx={{ color: colors.turquoise }} />
              </IconButton>
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Description>{res.placeholder}</Description>
              <IconButton style={{ cursor: "pointer" }} onClick={() => toggleEdit(i)}>
                <EditOutlined sx={{ color: colors.turquoise }} />
              </IconButton>
            </Box>
          )}
        </BoxDataUsuario>
        <Divider sx={{ color: colors.turquoise }} />
      </BoxDataUsuario>
    })

  }
  return (
    <Styled>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <BoxMiCuenta>
          <Header>
            <Title sx={{ fontSize: "24px", fontWeight: "600" }}>
              Mi Cuenta
            </Title>

            <Box sx={{ display: "flex", gap: "5px", alignItems: "flex-end" }}>
              <BoxImg>
                {preview ? (
                  <img src={preview} alt="Preview" className="img" style={{ width: '100%', objectFit: 'cover' }} />
                ) : (
                  <RenderImg img={LimsOil} className="img" />
                )}
              </BoxImg>
              <IconButton component="label">
                <EditOutlined sx={{ color: colors.turquoise }} fontSize='large' />
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  {...register("img", {
                    onChange: (e) => {
                      const file = e.target.files[0];
                      handleImageChange(file);
                    },
                  })}
                  onChange={handleImageChange}
                />
              </IconButton>
            </Box>


          </Header>

          <Body>
            {renderData()}
            <Box sx={{ width: "50%", margin: "auto", padding: "10px 0px" }}>
              <CustomButton
                label={"Confirmar"}
                type="submit"
                className="buttonColorDefault"
                size="large"
                fullWidth
                disableRipple
              />
            </Box>
          </Body>
        </BoxMiCuenta>

      </form>
    </Styled >
  )
}

export default MiCuenta