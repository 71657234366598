import { colors, fonts } from "../../../../assets/styles/theme.ts";
import { Box, Typography, styled } from "@mui/material";

export const Styled = styled(Box)`
  display: flex;
  flex-direction: column;
  height: auto;
`;
//Header
export const HeaderDashboard = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 40%; 
  @media (max-width: 768px) {
   width:100%;
   display:block;
  }
`;
export const BoxLabel = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 382px;
`;
export const TitleHeader = styled(Typography)`
color: ${colors.turquoise};
font-family: ${fonts.primary};
font-weight: 700;
font-size: 32px;
`;
export const DescriptionHeader = styled(Typography)`
color: ${colors.turquoise};
font-family: ${fonts.primary};
font-weight: 700;
font-size: 13px;
`;
export const BoxImg = styled(Box)`
  width: 232px;
  height: 122px;
`;
export const Body = styled(Box)`
  display: flex;
  gap: 20px;
  padding: 50px 0px 0px 0px;
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

//Left
export const DashBoardLeft = styled(Box)`
  width: 40%;
  padding:10px;
  @media (max-width: 768px) {
   width:100% !important;
  }
`;
export const BoxHeaderLeft = styled(Box)`
  background: #EEF3F4;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const TitleLeft = styled(Typography)`
color: ${colors.blueDark};
font-family: ${fonts.primary};
font-weight: 600;
font-size: 16px;
`;
export const DescriptionLeft = styled(Typography)`
color: ${colors.darkGrayDos};
font-family: ${fonts.primary};
font-weight: 400;
font-size: 13px;
`;
//Right
export const DashBoardRight = styled(Box)`
 width: 60%;
 @media (max-width: 768px) {
   width:100% !important;
  }
`;
export const BoxHeaderRight = styled(Box)`
  background: #EEF3F4;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const TitleRight = styled(Typography)`
color: ${colors.blueDark};
font-family: ${fonts.primary};
font-weight: 600;
font-size: 16px;
`;
export const DescriptionRight = styled(Typography)`
color: ${colors.darkGrayDos};
font-family: ${fonts.primary};
font-weight: 400;
font-size: 13px;
`;
export const BoxTable = styled(Box)`
  height: auto;
  padding: 10px 0px;
  .styledTables{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;
//Footer 
export const DashBoardFooter = styled(Box)`
  width: 90%;
  display: flex;
  gap: 20px;
  margin: auto;
  padding: 20px 0px;
  @media (max-width: 768px) {
   display: flex;
   flex-direction: column;
  }
`;
export const BoxLabelFooter = styled(Box)`
  display: flex;
  flex-direction: column;
  background: #EEF3F4;
  width: 20%;
  text-align: center;
  padding: 10px;
  gap: 20px;
  @media (max-width: 768px) {
   width: 100%;
   padding:0px;
  }
`;
export const TitleFooter = styled(Typography)`
color: ${colors.blueDark};
font-family: ${fonts.primary};
font-weight: 600;
font-size: 16px;
`;
export const DescriptionFooter = styled(Typography)`
color: ${colors.darkGrayDos};
font-family: ${fonts.primary};
font-weight: 400;
font-size: 13px;
`;
export const BoxTableFooter = styled(Box)`
  width: 80%;
  height: 40%;
  .styledTables{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  @media (max-width: 768px) {
   width: 100%;
  }
`;

